import {combineReducers} from "redux";
import {actionTypesConstructor, dispatchAction} from "../utils";
import url from "../../utils/url";
import {POST_DATA} from "../middlewares/api";
import {ethers} from "ethers";
import {WEB3} from "../middlewares/web3Api";
import {changeNetwork} from "../utils/wallet_helper";
import graphAbi from "../../utils/abi/graphAbi";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
const provider = window.ethereum ? new ethers.providers.Web3Provider(window.ethereum) : "";

export const types = {
    CONFIGURE_WALLET: "APP/CONFIGURE/WALLET_CONNECT",
    CONFIGURE_WALLET_FAILURE: "APP/CONFIGURE/WALLET_CONNECT/FAILURE",
    WALLET_CONNECT: "APP/SET/WALLET_CONNECT",
    WALLET_DISCONNECT: "APP/DISCONNECT/WALLET",

    USER_ETH_BALANCE: actionTypesConstructor(
        "APP/USER_ETH_BALANCE_REQUEST",
        "APP/USER_ETH_BALANCE_SUCCESS",
        "APP/USER_ETH_BALANCE_FAILURE"
    ),

    SEND_TX: actionTypesConstructor(
        "APP/SEND_TX_REQUEST",
        "APP/SEND_TX_SUCCESS",
        "APP/SEND_TX_FAILURE"
    ),

    SEND_TXH: actionTypesConstructor(
        "APP/SEND_TXH_REQUEST",
        "APP/SEND_TXH_SUCCESS",
        "APP/SEND_TXH_FAILURE"
    )
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
    isWalletInstalled: true,
    isWalletConnected: false,
    sendTxStatus: false,
    sendTxHStatus: false,
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
    walletConfigurator: () => {
        return async (dispatch, getState) => {
            if (typeof window.ethereum !== "undefined") {
                const address = await loadUserWeb3();
                const chain = getState().entities.wallet.chainId;
                return await dispatch({
                    type: types.CONFIGURE_WALLET,
                    payload: {address, chain},
                });
            } else {
                console.log("[MetaMask:Installation:CHECK]: false");
                return await dispatch({ type: types.CONFIGURE_WALLET_FAILURE, message: "Please install MetaMask", error: "Digital Wallet Installation Error!" });
            }
        };
    },
    checkUserEthBalance: () => {
        return async (dispatch, getState) => {
            const { address, chainId } =  getState().entities.wallet;
            await dispatch({
                type: types.USER_ETH_BALANCE.request()
            })
            try{
                const ethBalance = await getBalance(address, chainId);
                return await dispatch({
                    type: types.USER_ETH_BALANCE.success(),
                    response: {ethBalance}
                })
            }catch (e) {
                return await dispatch({
                    type: types.USER_ETH_BALANCE.failure()
                })
            }
        }
    },
    sendTx: (amount, recipientAddress)=>{
        return async (dispatch, getState) => {

            const desiredNetworkId = getState().entities.poc.configInfo.network;
            try {
                const response = await changeNetwork(desiredNetworkId);
                await dispatch({
                    type: types.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                return await dispatch(
                    {
                        type: types.SEND_TX.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }


            if(!ethers.utils.isAddress(recipientAddress)){
                return await dispatch(
                    {
                        type: types.SEND_TX.failure(),
                        error: "Invalid Address",
                        message: "Invalid Address"
                    }
                )
            }


            const accounts = await getAccounts();
            const parsedAmount = etherToWei(amount.toString());
            const transaction = {
                from: accounts[0],
                to: recipientAddress,
                value: parsedAmount,
            };

            await dispatch(
                {[WEB3]: {
                        types: types.SEND_TX.all(),
                        func:depositToContract(amount, recipientAddress),
                        payload: {amount}
                    }
                }
            )
        }
    },
    sendTxH: (address) => {
        return async (dispatch, getState) => {
            const endPoint = url.sendTXHash();

            return await dispatch(
                dispatchAction(
                    POST_DATA,
                    types.SEND_TXH.all(),
                    endPoint,
                    null,
                    JSON.stringify({txhash: address})
                )
            )
        }
    },
    setWallet: (wallet)=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.WALLET_CONNECT,
                payload: wallet
            })
        }
    },
    disconnectWallet: ()=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.WALLET_DISCONNECT,
            })
        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const data = (state = initialState, action) => {
    switch (action.type) {
        case types.CONFIGURE_WALLET_FAILURE:
            return {...state, isWalletInstalled: false}
        case types.CONFIGURE_WALLET:
            return action.payload.address ? {...state, isWalletConnected: true} : {...this?.state}
        case types.WALLET_CONNECT:
            return {...state, isWalletConnected: true}
        case types.SEND_TX.request():
            return {...state, sendTxStatus: true}
        case types.SEND_TX.success():
            return {...state, sendTxStatus: false}
        case types.SEND_TX.failure():
            return {...state, sendTxStatus: false}
        case types.SEND_TXH.request():
            return {...state, sendTxHStatus: true}
        case types.SEND_TXH.success():
            return {...state, sendTxHStatus: false}
        case types.SEND_TXH.failure():
            return {...state, sendTxHStatus: false}
        case types.WALLET_DISCONNECT:
            return initialState;
        default:
            return state;
    }
}

const reducer = combineReducers({data})
export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getWalletStatus = (state) =>{
    return state.wallet.data
}

export const loadUserWeb3 = async () => {
    const accounts = await window.ethereum.request({
        method: 'eth_accounts',
    })

    return accounts.length === 0 ? null : accounts[0];
};

/***********************************************************************************************************************
 * 													UTILS  														   *
 * *********************************************************************************************************************/

export async function changeToGoerli() {
    if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum);

        // Check the current network
        const currentNetwork = await provider.getNetwork();

        // Goerli network ID is 5
        if (currentNetwork.chainId !== 5) {
            try {
                // Request the user to switch to the Goerli network
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x5' }], // 0x5 is the Goerli chain ID in hex
                });
                console.log('Network switched to Goerli');
            } catch (error) {
                console.error('Error switching to Goerli:', error);
                if (error.code === 4902) {
                    console.error('Goerli network not added to the wallet. Please add it manually.');
                }
            }
        } else {
            console.log('Already on Goerli network');
        }
    } else {
        console.error('Ethereum provider not detected. Please install MetaMask or another compatible wallet.');
    }
}

async function getAccounts() {
    if (typeof window.ethereum !== 'undefined') {
        try {
            // Request the user to connect their wallet
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            return accounts;
        } catch (error) {
            console.error('Error getting accounts:', error);
        }
    } else {
        console.error('Ethereum provider not detected. Please install MetaMask or another compatible wallet.');
    }
}

function etherToWei(etherValue) {
    const weiValue = ethers.utils.parseUnits(etherValue, 'ether');
    // Convert the wei amount to a hexadecimal string
    const hexWeiAmount = weiValue.toHexString();
    return hexWeiAmount;
}

async function makePayment(transaction) {
            // Send the transaction and wait for the transaction hash
            const txResponse = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transaction],
            });

            console.log('Transaction sent:', txResponse);

            // Wait for the transaction to be confirmed
            const txReceipt = await provider.waitForTransaction(txResponse);
            console.log('Transaction confirmed:', txReceipt.transactionHash);
            return { txResponse, txReceipt}
}

async function depositToContract(amount, contractAddress) {
    try {
        const signer = provider.getSigner();
        const graphContract = new ethers.Contract(contractAddress, graphAbi, provider).connect(signer);
    }catch (error) {
        console.error('Error connecting to contract:', error);
        throw error;
    }
    try {
    const txResponse = await graphContract.deposit(
        ethers.utils.parseEther(amount.toString()), {value: ethers.utils.parseEther(amount.toString())}
    ).catch((error) => {
        console.error('Error depositing to contract:', error);
    });

    const txReceipt = await txResponse.wait();

    return {txReceipt}
    }catch (error) {
        console.error('Error in transaction:', error);
        throw error;
    }
}

async function getBalance(address, chainId) {
    if (typeof window.ethereum !== 'undefined') {
        try {
            // Request the user to connect their wallet if not already connected
            await window.ethereum.request({ method: 'eth_requestAccounts' });

            // Get the selected address (current account)
            const account = address;

            // Determine the network URL based on the chainId
            let networkUrl;
            switch(chainId) {
                case 1: // Mainnet
                    networkUrl = 'https://rpc.ankr.com/eth';
                    break;
                // Add cases for other networks as needed
                case 11155111: // Goerli
                    networkUrl = 'https://rpc.ankr.com/eth_sepolia';
                    break;
                default:
                    throw new Error('Unsupported chainId');
            }

            // Create a new ethers provider connected to the specified network
            const provider = new ethers.providers.JsonRpcProvider(networkUrl);

            // Fetch the balance
            const balanceWei = await provider.getBalance(account);

            // Convert the balance from Wei to Ether
            return ethers.utils.formatEther(balanceWei);
        } catch (error) {
            console.error('Error getting balance:', error);
        }
    } else {
        console.error('Ethereum provider not detected. Please install MetaMask or another compatible wallet.');
    }
}
