import url from "../../utils/url";
import {actionTypesConstructor, dispatchAction} from "../utils";
import {POST_DATA} from "../middlewares/api";
import {InitialStateProps as GraphStudioEntityStateProps} from "./entities/graphStudio";
import { constants, ethers } from "ethers";
import {testNets} from "./entities/poc";
import {changeNetwork} from "../utils/wallet_helper";
import {types as walletTypes} from "./wallet";
import {types as asEditorTypes} from "./asEditor";
import {LOGS} from "./entities/asEditor";
import {publish, CLEYaml} from "@hyperoracle/cle-api-test";
import {YamlProps} from "./entities/zkGraphs";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/

export const addressFactory = '0xE484E5B8b71aA7955d1De4D52737BF436eBf9970'
export const abiFactory = [
    'function getAllZkg() external view returns (address[] memory)',
    'function registry(address destAddr, address bountyToken, uint256 bountyReward, bytes32 dspID, string memory _graphURI, uint256 pointX, uint256 pointY) external returns (address graph)',
    'function getGraphBycreator(address creator) external view returns (address[] memory)',
    'function getGraphInfoByAddress(address graph) external view returns (address creator, uint256 bountyReward, address verifier, address destAddr, string memory graphURI)',
]


export const types = {
    GRAPH_STUDIO_CREATION_PINATA_UPLOAD: actionTypesConstructor("APP/GRAPH_STUDIO_CREATION/PINATA_UPLOAD/REQUEST", "APP/GRAPH_STUDIO_CREATION/PINATA_UPLOAD/SUCCESS", "APP/GRAPH_STUDIO_CREATION/PINATA_UPLOAD/FAILURE"),
    GRAPH_STUDIO_PUBLISH: actionTypesConstructor("APP/GRAPH_STUDIO_CREATION/PUBLISH/REQUEST", "APP/GRAPH_STUDIO_CREATION/PUBLISH/SUCCESS", "APP/GRAPH_STUDIO_CREATION/PUBLISH/FAILURE"),
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
type InitialStateProps = {
    isPosting2Pinata: boolean,
    isPublishing: boolean,
}
const initialState: InitialStateProps = {
    isPosting2Pinata: false,
    isPublishing: false,
}
/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const actions = {
    uploadToPinata: () => {
        return async (dispatch: any, getState: any) => {

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: LOGS.upload.request,
                    prefix: LOGS.prefix
                }
            })

            const userAddress = getState().entities.wallet.address;
            const wasm = getState().entities.asEditor.moduleWasm;
            const mapping = getState().entities.asEditor["src/mapping.ts"];
            const graphInfo = getState().entities.graphStudio;
            const directoryName = `${graphInfo.graphName} - ${userAddress}`;


            const yamlFromEditor = getState().entities.asEditor["src/zkgraph.yaml"];
            try {
                CLEYaml.fromYamlContent(getState().entities.asEditor["src/zkgraph.yaml"]) as YamlProps;
            } catch (error) {
                console.error(error);
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.upload.failed}]${error}.`,
                        prefix: LOGS.prefix
                    }
                });
                return await dispatch({
                    type:  types.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.failure(),
                    error: error,
                    message: "Yaml file is not valid."
                });
            }

                const blob = new Blob([mapping], { type: "text/typescript" });

                const mappingFile = new File([blob], "mapping.ts");
                const wasmFile = new File([ new Blob([wasm], {type: "application/wasm"})], "zkgraph.wasm");
                const yamlFile = new File([new Blob([yamlFromEditor], { type: 'text/yaml' })], "zkgraph.yaml");

                const formData = new FormData();
                formData.append("file", mappingFile, `${directoryName}/mapping.ts`);
                formData.append("file", wasmFile, `${directoryName}/zkgraph.wasm`);
                formData.append("file", yamlFile, `${directoryName}/zkgraph.yaml`);

                const metadata = JSON.stringify({
                    name: directoryName,
                });
                formData.append('pinataMetadata', metadata);

                const endpoint = url.uploadZkgraph2Pinata();

                await dispatch(
                    dispatchAction(
                        POST_DATA,
                        types.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.all(),
                        endpoint,
                        null,
                        formData));
                return await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: LOGS.upload.success,
                        prefix: LOGS.prefix
                    }
                });
        }
    },
    publishZkGraph: () => {
        return async (dispatch: any, getState: any) => {
            const desiredNetworkId = getState().entities.poc.configInfo.network;
            const _bountyRewardPerTrigger = getState().entities.graphStudio.bounty.rewardPerTrigger;
            const graphDeployedContractAddress = getState().entities.poc.customizedWasmUint8array.deploy[getState().entities.poc.configInfo.network].deployedAddress;
            const destinationContractAddress = getState().entities.graphStudio.destinationContractAddress;
            const graphIPFS = getState().entities.graphStudio.IPFS.IpfsHash;

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: LOGS.publish.request,
                    prefix: LOGS.prefix
                }
            })

            try {
                const response = await changeNetwork(desiredNetworkId);
                await dispatch({
                    type: walletTypes.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.publish.failed}]Network change failed`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch(
                    {
                        type: types.GRAPH_STUDIO_PUBLISH.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }


            await dispatch({
                type: types.GRAPH_STUDIO_PUBLISH.request()
            });
            try{
            // @ts-ignore
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

             const contractFactory = new ethers.Contract(addressFactory, abiFactory, signer);

             const sender = await signer.getAddress();
             console.log(sender, contractFactory)
            const bountyRewardPerTrigger = _bountyRewardPerTrigger * Math.pow(10, 9);
            const wasmUint8Array =  getState().entities.poc.customizedWasmUint8array.uint8array;
            const yamlFromEditor = CLEYaml.fromYamlContent(getState().entities.asEditor["src/zkgraph.yaml"]);
            const receipt = await publish({wasmUint8Array, cleYaml: yamlFromEditor as CLEYaml}, "https://rpc.zkwasmhub.com:8090", provider as any, graphIPFS, bountyRewardPerTrigger, signer as any );
            console.log(receipt);

           const graphAfter = await contractFactory.getGraphBycreator(sender);
           console.log(graphAfter);

           await dispatch({
               type: asEditorTypes.PUSH_LOG,
                payload: {
                   content: `[${LOGS.publish.success}]graphAddress: ${graphAfter[graphAfter.length-1]}`,
                    prefix: LOGS.prefix
                }
           })
            return dispatch({
                type: types.GRAPH_STUDIO_PUBLISH.success(),
                response: {
                    graphAddress: graphAfter[graphAfter.length-1]
                }
            });
            }catch (e:any){
                console.error(e);
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.publish.failed}]${e.message}`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch(
                    {
                        type: types.GRAPH_STUDIO_PUBLISH.failure(),
                        error: "Publish failed",
                        message: e.message
                    }
                )
            }

        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.request():
            return {...state, isPosting2Pinata: true};
        case types.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.success():
            return {...state, isPosting2Pinata: false};
        case types.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.failure():
            return {...state, isPosting2Pinata: false};
        case types.GRAPH_STUDIO_PUBLISH.request():
            return {...state, isPublishing: true};
        case types.GRAPH_STUDIO_PUBLISH.success():
            return {...state, isPublishing: false};
        case types.GRAPH_STUDIO_PUBLISH.failure():
            return {...state, isPublishing: false};
        default:
            return state;
    }
}

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getGraphStudioModule = (state:any) => state.graphStudio;

/***********************************************************************************************************************
 * 													UTIL  														   *
 * *********************************************************************************************************************/
export const genYAML = ({graphName, destinationFunctionName, destinationContractAddress, profileImage, description, network}: GraphStudioEntityStateProps, sourceAddress: string, sourceEventName:string) => {
    const yaml = `
name: '${graphName}'
specVersion: 0.0.2
description: '${description}'
repository: https://github.com/hyperoracle/zkgraph
dataSources:
  - kind: ethereum/contract
    network: '${network}'
    source:
      address: '${sourceAddress}'
    mapping:
      kind: ethereum/events
      apiVersion: 0.0.1
      language: wasm/assemblyscript
      file: ./mapping.ts
      eventHandlers:
        - event: '${sourceEventName}'
          handler: handleEvents
dataDestinations:
  - kind: ethereum/contract
    network: '${network}'
    destination:
      address: '${destinationContractAddress}'
`
    return new Blob([yaml], { type: 'text/yaml' });
}

