import { useEffect, useState } from "react";

function RunningText({ text = "NaN", style = {}, speed = 50 }) {
  const [_text, setText] = useState(text);

  function RandomSymbolStr(inputString) {
    const symbols = ["!", "@", "#", "$", "%", "^", "&", "*"];
    let result = "";
    for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] === " ") {
        result += " ";
      } else {
        const randomIndex = Math.floor(Math.random() * symbols.length);
        result += symbols[randomIndex];
      }
    }
    return result;
  }

  useEffect(() => {
    mouseIn();
  }, []);

  const mouseIn = () => {
    let counter = 0;
    function transform() {
      counter++;
      let temp_text =
        text.slice(0, counter) +
        RandomSymbolStr(text.slice(counter, text.length - 1));
      setText(temp_text);
      if (counter < text.length) setTimeout(transform, speed);
    }
    transform();
  };

  const mouseOut = () => {
    setText(text);
  };

  return (
    <div onMouseEnter={mouseIn} onMouseOut={mouseOut} style={{ ...style }}>
      {_text.split("").map((a) => a)}
    </div>
  );
}

export default RunningText;
