import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import {
    actions
} from "../../redux/modules/wallet";
import {getWallet} from "../../redux/modules/entities/wallet";
import {getWalletStatus} from "../../redux/modules/wallet";
import WalletConnectContext from "../../contexts/WalletConnectContext";
import {useContext} from "react";

const useASEditor = () => {
    const dispatch = useDispatch();

    const walletDispatcher = bindActionCreators(
        actions,
        dispatch
    );

    return {
        walletConfigurator:  async () => await walletDispatcher.walletConfigurator(),
        walletSetter: async (wallet) => await walletDispatcher.setWallet(wallet),
        walletDisconnetor: async () => await walletDispatcher.disconnectWallet(),
        walletGetter: useSelector(state => getWallet(state)),
        walletStatusGetter: useSelector(state => getWalletStatus(state)),
        userEthBalanceFetcher: async () => await walletDispatcher.checkUserEthBalance(),
        txSender: async (tx, recipientAddress) => await walletDispatcher.sendTx(tx, recipientAddress),
        txHSender: async (address) => await walletDispatcher.sendTxH(address),
        walletConnector:  useContext(WalletConnectContext)
    }
}

export default useASEditor;
