import {combineReducers} from "redux";
import {actionTypesConstructor, dispatchAction} from "../utils";
import url from "../../utils/url";
import {POST_DATA} from "../middlewares/api";
import {LOGS} from "./entities/asEditor";
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

const SENDTOCOMPILE = {
    unsend: "UNSEND",
    sending: "SENDING",
    success: "SUCCESS",
    failure: "FAILURE",
}
/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
   ASEDITOR_TAB_CHANGE: "ASEDITOR/TAB_CHANGE",
   ASEDITOR_TAB_DELETE: "ASEDITOR/TAB_DELETE",
   ASEDITOR_CODE: "ASEDITOR/CODE_UPDATE",
   ASEDITOR_NEW_FILE: "ASEDITOR/NEW_FILE/ADD",
   ASEDITOR_DELETE_FILE: "ASEDITOR/FILE/DELETE",
   ASEDITOR_SENDTOCOMPILE: actionTypesConstructor("ASEDITOR/SEND_TO_COMPILE/REQUEST",
       "ASEDITOR/SEND_TO_COMPILE/SUCCESS",
       "ASEDITOR/SEND_TO_COMPILE/FAILURE"),
   PUSH_LOG: "ASEDITOR/PUSH_LOG",
   RESET_FILE: "ASEDITOR/RESET_FILE",
   SAVE_FILES: actionTypesConstructor("ASEDITOR/SAVE_FILES/REQUEST","ASEDITOR/SAVE_FILES/SUCCESS","ASEDITOR/SAVE_FILES/FAILURE"),
   RETRIEVE_FILES: actionTypesConstructor("ASEDITOR/RETRIEVE_FILES/REQUEST","ASEDITOR/RETRIEVE_FILES/SUCCESS","ASEDITOR/RETRIEVE_FILES/FAILURE"),
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
    tabs: [{id:"src/mapping.ts", tab: "mapping.ts"}, {id:"README.md", tab: "README.md"}],
    activeTab: "README.md",
    isSendingToCompile: SENDTOCOMPILE.unsend,
}


/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
    asEditorRetrieveFiles: ()=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.RETRIEVE_FILES.request(),
            });

            try{
                const loadedFiles = loadFromLocalStorage("HOASEditorFiles");

                await dispatch({
                    type: types.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.retrieveFiles.success}] PATHs(${Object.keys(loadedFiles).length}): ${Object.keys(loadedFiles).join(", ")}`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch({
                    type: types.RETRIEVE_FILES.success(),
                    payload: loadedFiles
                });
            }catch (e){
                await dispatch({
                    type: types.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.retrieveFiles.failed}]: ${e.message}`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch({
                    type: types.RETRIEVE_FILES.failure(),
                });
            }
        }
    },
    asEditorSaveFilesChange: ()=>{
        return async (dispatch, getState) =>{
            const fileNames = Object.keys(getState().entities.asEditor).filter((fileName)=> fileName.startsWith("src/"));
            let files = {};
            fileNames.map((fileName)=> (files[fileName] = getState().entities.asEditor[fileName]));

            await dispatch({
                type: types.SAVE_FILES.request(),
            });

            await dispatch({
                    type: types.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.saveFiles.request}]: PATHs(${fileNames.length}): ${fileNames.join(", ")}`,
                        prefix: LOGS.prefix
                    }
            })

            try{
                saveToLocalStorage("HOASEditorFiles", files);
                await dispatch({
                    type: types.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.saveFiles.success}]`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch({
                    type: types.SAVE_FILES.success(),
                });
            }catch (e){
                console.log(e)
                await dispatch({
                    type: types.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.saveFiles.failed}]: ${e.message}`,
                        prefix: LOGS.prefix
                    }
                })
                return await dispatch({
                    type: types.SAVE_FILES.failure(),
                });
            }
        }
    },
    asEditorRestFile: (fileName)=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.RESET_FILE,
                payload: fileName
            })
        }
    },
    asEditorTabSwitch: ({id, tab})=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.ASEDITOR_TAB_CHANGE,
                payload: {id, tab}
            })
        }
    },
    asEditorTabDelete: ({id, tab})=>{
        return async (dispatch, getState) =>{
            await dispatch({
                type: types.ASEDITOR_TAB_DELETE,
                payload: {id, tab}
            })
        }
    },
    asEditorCodeUpdate: (value, {id, tab}) => {
        console.log("asEditorCodeUpdate: ", {id, tab})
        return async (dispatch, getState) => {
            await dispatch({
                type: types.ASEDITOR_CODE,
                payload: {value, asEditorCurrentTab: {id, tab}}
            })
        }
    },
    addNewFile: (file)=>{
        return async (dispatch, getState) =>{
            console.log(" addNewFile: ",  getState().entities.asEditor.files.children.filter((item) => item.id === file.id).length === 0 )
            getState().entities.asEditor.files.children.filter((item) => item.id === file.id).length === 0 &&
            await dispatch({
                type: types.ASEDITOR_NEW_FILE,
                payload: file
            })
        }
    },
    deleteNewFile: (fileId)=>{
        return async (dispatch, getState) =>{
            getState().entities.asEditor.files.children.filter((item) => item.id === fileId).length !== 0 &&
            await dispatch({
                type: types.ASEDITOR_DELETE_FILE,
                payload: fileId
            })
        }
    },
    sendToCompile: (code) => {
        return async (dispatch, getState) =>{

            await dispatch({
                type: types.PUSH_LOG,
                payload: {content: LOGS.compile.request, prefix: LOGS.prefix}
            })
            const sourceContractAddress = getState().entities.poc.configInfo.sourceContractAddress; // ethers.utils.getAddress
            const sourceEventName = getState().entities.poc.configInfo.sourceEventABI;
            const yamlFromEditor = getState().entities.asEditor["src/zkgraph.yaml"];
            console.log(yamlFromEditor)
            const yamlFile = new File([new Blob([yamlFromEditor], { type: 'text/yaml' })], "src/zkgraph.yaml", { type: 'text/yaml' });

            if(sourceContractAddress === "" || sourceEventName === ""){
                await dispatch({
                    type: types.PUSH_LOG,
                    payload: {content: `[${LOGS.compile.failed}]Please fill in the source contract address and event name`, prefix: LOGS.prefix}
                })
                return await dispatch({
                    type: types.ASEDITOR_SENDTOCOMPILE.failure(),
                    response: {error: "Please fill in the source contract address and event name"}
                })
            }


            const endpoint = url.sendToCompile();
            let dataForm = new FormData();
            dataForm.append("yamlFile", yamlFile)
            dataForm.append("wasmFile", code);

            console.log(dataForm.get("yamlFile"))
            console.log(dataForm.get("wasmFile"))

            await dispatch(dispatchAction(POST_DATA, types.ASEDITOR_SENDTOCOMPILE.all(), endpoint, null, dataForm))
            return await dispatch({
                type: types.PUSH_LOG,
                payload: {content: LOGS.compile.success, prefix: LOGS.prefix}
            })
        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const data = (state = initialState, action) => {
    switch (action.type) {
        case types.ASEDITOR_TAB_CHANGE:
            console.log(state.tabs, action.payload,  state.tabs.includes(action.payload.tab) );
            return {...state,tabs: state.tabs.filter(each => each.id === action.payload.id).length > 0 ? [...state.tabs] : [...state.tabs, action.payload], activeTab: action.payload.id}
        case types.ASEDITOR_TAB_DELETE:
            function findTabIndex(map, tabId) {
                for (let i = 0; i < map.length; i++) {
                    if (map[i].id === tabId) {
                        return i;
                    }
                }
                return -1;
            }
            const index = findTabIndex(state.tabs, action.payload.id)
            if(index === -1) return state;
            const activeTabIndex = findTabIndex(state.tabs, state.activeTab);
            const updatedTabs = state.tabs.filter((tab) => tab.id !== action.payload.id);
            const theGap = activeTabIndex - index;
            return {...state,tabs: updatedTabs, activeTab: theGap < 0 ? updatedTabs[updatedTabs.length - Math.abs(theGap)].id : updatedTabs[ activeTabIndex - 1].id};
        case types.ASEDITOR_SENDTOCOMPILE.request():
            return {...state, isSendingToCompile: SENDTOCOMPILE.sending}
        case types.ASEDITOR_SENDTOCOMPILE.success():
            console.log("types.ASEDITOR_SENDTOCOMPILE.success(): ", action.response)
            return {...state, isSendingToCompile: SENDTOCOMPILE.success}
        case types.ASEDITOR_SENDTOCOMPILE.failure():
            console.log("types.ASEDITOR_SENDTOCOMPILE.failure()", action.response)
            return {...state, isSendingToCompile: SENDTOCOMPILE.failure}
        default:
            return state;
    }

}

const reducer = combineReducers({data})
export default reducer;


/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getAsEditorTabs = (state) => {
    return state.asEditor.data;
}


const saveToLocalStorage = (key, data) => {
    const hashedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_HO_SAVED_FILES).toString();
    window.localStorage.setItem(key, hashedData);
};

export const loadFromLocalStorage = (key) => {
    const hashedData = window.localStorage.getItem(key);
    if (hashedData) {
        const bytes = CryptoJS.AES.decrypt(hashedData, process.env.REACT_APP_HO_SAVED_FILES);
        const originalData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(originalData);
    }
    return null;
};
