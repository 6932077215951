export default [
  {
    "inputs": [
      {
        internalType: 'contract IAIOracle',
        name: '_aiOracle',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    "inputs": [
      {
        internalType: 'contract IAIOracle',
        name: 'expected',
        type: 'address'
      },
      {
        internalType: 'contract IAIOracle',
        name: 'found',
        type: 'address'
      }
    ],
    name: 'UnauthorizedCallbackSource',
    type: 'error'
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        internalType: 'uint256',
        name: 'modelId',
        type: 'uint256'
      },
      {
        "indexed": false,
        internalType: 'string',
        name: 'input',
        type: 'string'
      },
      {
        "indexed": false,
        internalType: 'string',
        name: 'output',
        type: 'string'
      }
    ],
    name: 'promptsUpdated',
    type: 'event'
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        internalType: 'address',
        name: 'sender',
        type: 'address'
      },
      {
        "indexed": true,
        internalType: 'uint256',
        name: 'modelId',
        type: 'uint256'
      },
      {
        "indexed": true,
        internalType: 'string',
        name: 'prompt',
        type: 'string'
      }
    ],
    name: 'promptRequest',
    type: 'event'
  },
  {
    "inputs": [],
    name: 'aiOracle',
    "outputs": [
      {
        internalType: 'contract IAIOracle',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    "inputs": [
      {
        internalType: 'uint256',
        name: 'modelId',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'prompt',
        type: 'string'
      }
    ],
    name: 'calculateAIResult',
    "outputs": [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    "inputs": [
      {
        internalType: 'uint256',
        name: 'modelId',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: 'prompt',
        type: 'string'
      }
    ],
    name: 'getAIResult',
    "outputs": [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    "inputs": [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      },
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    name: 'prompts',
    "outputs": [
      {
        internalType: 'string',
        name: '',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    "inputs": [
      {
        internalType: 'uint256',
        name: 'modelId',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: 'input',
        type: 'bytes'
      },
      {
        internalType: 'bytes',
        name: 'output',
        type: 'bytes'
      }
    ],
    name: 'storeAIResult',
    "outputs": [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
]
