import React, { lazy, Suspense } from "react";
import Home from "./pages/OraHome/index";
import OAO from "./pages/OAO/index";
import LoadingScreen from "./utils/LoadingScreen";
import WagmiProvider from "./contexts/wagmiContext";
import { Paper } from "@mui/material";
import PageLayout from "./layout/PageLayout";

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const MainLayout = Loadable(lazy(() => import("./layout")));
const OraMainLayout = Loadable(lazy(() => import("./layout/OraMainLayout")));
const ZKIndexing = Loadable(lazy(() => import("./pages/About/ZKIndexing")));
const ZKAutomation = Loadable(lazy(() => import("./pages/About/ZKAutomation")));
const ZKGraph = Loadable(lazy(() => import("./pages/ZKGraph")));
const GraphDetails = Loadable(
  lazy(() => import("./pages/App/Studio/GraphDetails"))
);
const Cases = Loadable(lazy(() => import("./pages/App/Cases")));
const Studio = Loadable(lazy(() => import("./pages/App/Studio")));
const GraphCreation = Loadable(
  lazy(() => import("./pages/App/Studio/GraphCreation"))
);
const CaseSinglePage = Loadable(
  lazy(() => import("./pages/App/Cases/caseSinglePage"))
);

const OpmlSDPage = Loadable(lazy(() => import("./pages/App/Opml/OraAi/SD")));
const OpmlLLMPage = Loadable(lazy(() => import("./pages/App/Opml/OraAi/LLM")));

const routes = [
  {
    path: "about",
    element: <MainLayout />,
    children: [
      // {
      //     path: "indexing",
      //     element: <ZKIndexing/>
      // },
      {
        path: "automation",
        element: <ZKAutomation />,
      },
    ],
  },
  // {
  //   path: "app",
  //   children: [
  //     {
  //       path: "cle/create",
  //       element: (
  //         <Paper
  //           sx={{
  //             height: "auto",
  //             width: "auto",
  //             minHeight: "100%",
  //             minWidth: "auto",
  //           }}
  //           square
  //           elevation={0}
  //         >
  //           <WagmiProvider>
  //             <GraphCreation />
  //           </WagmiProvider>
  //         </Paper>
  //       ),
  //     },
  //   ],
  // },
  // {
  //   path: "app",
  //   element: <OraMainLayout />,
  //   children: [
  //     {
  //       path: "opml/sd",
  //       element: <OpmlSDPage />,
  //     },
  //     {
  //       path: "opml/llm",
  //       element: <OpmlLLMPage />,
  //     },
  //   ],
  // },
  // {
  //   path: "app",
  //   element: <MainLayout />,
  //   children: [
  //     // {
  //     //     path: "cases",
  //     //     element: <Cases/>,
  //     // },
  //     // {
  //     //     path: "cases/:caseName",
  //     //     element: <CaseSinglePage/>
  //     // },
  //     // {
  //     //     path: "zkGraph/:graphAddress",
  //     //     element: <GraphDetails/>
  //     // },
  //     // {
  //     //     path: "zkGraphStudio",
  //     //     element: <Studio/>,
  //     // },
  //     // {
  //     //     path: "zkGraphStudio/myGraphs",
  //     //     element: <Studio/>,
  //     // },
  //     // {
  //     //     path: "zkGraphStudio/create",
  //     //     element: <GraphCreation/>
  //     // },
  //   ],
  // },
  {
    path: "/",
    element: <OraMainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "/app",
        element: <PageLayout />,
        children: [
          {
            path: "opml/:ai?",
            element: <OAO />,
          },
        ],
      },
    ],
  },
];

export default routes;
