import React from 'react';
import { FaDiagramProject } from 'react-icons/fa6';
import { SiYaml, SiBookstack } from 'react-icons/si';
import { BiLogoTypescript } from 'react-icons/bi';
import hoLogo from "../../../assets/Hyper Oracle Logo.svg";
import { CgReadme } from "react-icons/cg";

function createTreeFromPaths(flatFiles) {
    const root = {
        id: 'root',
        name: 'project',
        icon: <FaDiagramProject color={"#007acc"} fontSize={"1rem"} />,
        children: []
    };

    for (const [path, content] of Object.entries(flatFiles)) {
        let parts = path.split('/');
        let currentNode = root;

        for (let i = 0; i < parts.length; i++) {
            const part = parts[i];
            let childNode = (currentNode.children || []).find(child => child.name === part);

            if (!childNode) {
                childNode = {
                    id: parts.slice(0, i+1).toString().replaceAll(",", "/"),
                    name: part,
                    children: []
                };

                if (i === 0 && part === 'root') {
                    childNode.icon = <FaDiagramProject color={"#007acc"} fontSize={"1rem"} />;
                } else if (i === parts.length - 1 && part.endsWith('.ts')) {
                    childNode.icon = <BiLogoTypescript color={"#007acc"} fontSize={"1rem"} />;
                } else if(part.includes('hyperoracle')){
                    childNode.icon = <img alt={"logo"} src={hoLogo} style={{height: "1.2rem", width: "1.2rem"}} />
                } else if(part.endsWith('.yaml')){
                    childNode.icon = <SiYaml color={"#FF7B7B"} fontSize={"1rem"}/>;
                } else if(part.endsWith('.md')){
                    childNode.icon = <CgReadme fontSize={"1rem"} color={"#4caf50"}/>
                }
                else {
                    childNode.icon = <SiBookstack color={"#0CAF89"} fontSize={"1rem"}/>;
                }

                currentNode.children = (currentNode.children || []).concat(childNode);
            }

            if (i === parts.length - 1) {
                childNode.content = content;
                childNode.tab = part;
            }

            currentNode = childNode;
        }
    }

    console.log(root)
    return root;
}

export default createTreeFromPaths;
