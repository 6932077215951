import MirrorIcon from "../../assets/icons/socials/MirrorIcon";
import DiscordIcon from "../../assets/icons/socials/DiscordIcon";
import TitterIcon from "../../assets/icons/socials/TwitterIcon";
import GithubIcon from "../../assets/icons/socials/GithubIcon";
import TelegramIcon from "../../assets/icons/socials/TelegramIcon";
import { Link, useTheme } from "@mui/material";

function FooterBar({ style = {} }) {
  const theme = useTheme();

  const socialItems = [
    {
      logo: (
        <MirrorIcon
          fill={theme.palette.primary.main}
          height={"25px"}
          width={"25px"}
          hover={theme.palette.primary.contrastText}
        />
      ),
      link: "https://mirror.xyz/hyperoracleblog.eth",
    },
    {
      logo: (
        <DiscordIcon
          fill={theme.palette.primary.main}
          height={"25px"}
          width={"25px"}
          hover={theme.palette.primary.contrastText}
        />
      ),
      link: "https://discord.gg/MgyYbW9dQj",
    },
    {
      logo: (
        <TitterIcon
          fill={theme.palette.primary.main}
          height={"25px"}
          width={"25px"}
          hover={theme.palette.primary.contrastText}
        />
      ),
      link: "https://twitter.com/OraProtocol",
    },
    {
      logo: (
        <GithubIcon
          fill={theme.palette.primary.main}
          height={"25px"}
          width={"25px"}
          hover={theme.palette.primary.contrastText}
        />
      ),
      link: "https://github.com/hyperoracle",
    },
    {
      logo: (
        <TelegramIcon
          fill={theme.palette.primary.main}
          height={"25px"}
          width={"25px"}
          hover={theme.palette.primary.contrastText}
        />
      ),
      link: "https://t.me/Hyper_Oracle",
    },
  ];

  return (
    <div className="footer" style={style}>
      <div className="footer_content">
        <div className="copyright"> 2024 HYPER ORACLE</div>

        <div className="social_media">
          {socialItems.map((item: any, k: number) => {
            return (
              <Link
                href={item.link}
                key={k}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                {item.logo}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FooterBar;
