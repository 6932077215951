import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TRACKING_ID = "G-RNFNW8N187";
ReactGA.initialize(TRACKING_ID)

function PageViewTracker() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return null;
}

export default PageViewTracker;