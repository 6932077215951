import {testNets} from "../modules/entities/poc";
import {ethers} from "ethers";

export async function connectWallet() {
    if (window.ethereum) {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log("Wallet connected");
        } catch (error) {
            console.error("User denied account access", error);
            throw new Error('User denied account access');
        }
    } else {
        const errorMsg = 'Ethereum provider not detected. Please install MetaMask or another compatible wallet.';
        console.error(errorMsg);
        throw new Error(errorMsg);
    }
}

export async function changeNetwork(networkId: number) {
    const curNet = testNets.find(net => net.value === networkId);

    try {
        await connectWallet();
    } catch (error:any) {
        return Promise.reject(error.message);
    }

    try {
        let provider = new ethers.providers.Web3Provider(window.ethereum as any);
        const currentNetwork = await provider.getNetwork();

        if (currentNetwork.chainId !== networkId) {
            await switchNetwork(curNet);
            provider = new ethers.providers.Web3Provider(window.ethereum as any);
        } else {
            console.log(`Already on ${curNet?.name} network`);
        }
        const accounts = await window!.ethereum!.request({ method: 'eth_accounts' });
        const userAddress = accounts[0];  // Assume the first account is the user's address
        const chainId = currentNetwork.chainId;

        // Get the user's ETH balance
        const balanceWei = await provider.getBalance(userAddress);
        const balanceEth = ethers.utils.formatEther(balanceWei);

        return Promise.resolve({ response: "success",userAddress: userAddress,
            chainId: chainId, "balance": balanceEth });
    } catch (error:any) {
        console.error("Error handling network operations", error);
        return Promise.reject(error.message);
    }
}

async function switchNetwork(curNet: any) {
    try {
        await window.ethereum!.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: curNet!.hex }],
        });
        console.log('Network switched to: ', curNet?.name);
    } catch (error) {
        console.error('Error switching to network:', error);
        throw error;  // re-throw the error to be caught in the outer try-catch block
    }
}

export async function getNetwork() {
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    const currentNetwork = await provider.getNetwork();
    return currentNetwork
}
