import "./style.css";
import { useState, useEffect, useRef } from "react";
import paper_frame from "../../assets/ai/paper_frame.svg";
import stable_diffusion from "../../assets/ai/stable_diffusion.png";
import llma from "../../assets/ai/llma.png";
import CURVE_TOP from "../../assets/innercurveTop.png";
import CURVE_TOP_HALF from "../../assets/innercurveTop_half.png";
import CURVE_RIGHT from "../../assets/innercurveRight.png";
import CURVE_LEFT from "../../assets/innercurveLeft.png";
import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";
import { Link } from "react-router-dom";
import RunningText from "../../components/RunningText";
import FooterBar from "../../components/FooterBar";
import defaultopml from "../../assets/ora/defultopml.png";
import Circle from "../../components/PracticleEffect/Circle";
import { useParams, useNavigate } from "react-router-dom";
import doElementsIntersect from "../../utils/doElementsIntersect";

function OAO() {
  const { ai } = useParams();
  const navigate = useNavigate();
  const circleRef = useRef(null);
  const btmCircleRef = useRef(null);
  const [isIntersect, setIsIntersect] = useState(false);

  useEffect(() => {
    const OnScroll = () => {
      setIsIntersect(
        doElementsIntersect(circleRef.current, btmCircleRef.current)
      );
    };
    addEventListener("wheel", OnScroll);
    addEventListener("touchmove", OnScroll);

    return () => {
      removeEventListener("wheel", OnScroll);
      removeEventListener("touchmove", OnScroll);
    };
  }, []);

  return (
    <>
      <div
        className="bg_particle rowc"
        style={{ opacity: isIntersect ? "0" : "0.1" }}
      >
        <Circle
          entranceAnimation={false}
          color={"white"}
          width={window.innerWidth / 2}
          height={window.innerWidth / 2}
        />
        <div
          ref={circleRef}
          style={{
            position: "absolute",
            left: 0,
            top: "30%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "40vh",
          }}
        ></div>
      </div>

      <section className="ai_one colc">
        <div className="blue">OAO</div>
        <h1>WORLD'S FIRST AI ORACLE</h1>
        <h2>
          ONCHAIN AI ORACLE (OAO) ENABLES AI INFERENCE IN ANY SMART CONTRACT
        </h2>

        <div className="illustration rowc">
          <div className="reminder">* OAO can be applied to any model.</div>

          <div className="frame_text rowc">
            <img src={paper_frame} alt="" />
            <span>
              ANY <br /> SMART CONTRACT
            </span>
          </div>
          <div className="colc arrows">
            <span>INFERENCE [PROMPT, "LLAMA2-13B"]*</span>
            <div className="arrow"></div>
            <span>RETURN &#123;RESULT&#125;</span>
          </div>
          <div className="frame_text rowc">
            <img src={paper_frame} alt="" />
            <span>
              OAO <br /> ONCHAIN AI ORACLE
            </span>
          </div>
        </div>
        <img
          style={{ position: "absolute", left: "17rem", top: 0, width: "5rem" }}
          src={CURVE_TOP}
          alt=""
        />
        <img
          style={{
            position: "absolute",
            right: "0",
            top: "55vh",
            width: "2.5rem",
          }}
          src={CURVE_RIGHT}
          alt=""
        />
        <img
          style={{
            position: "absolute",
            left: "0",
            bottom: "0",
            width: "2.5rem",
            transform: "translateY(50%)",
          }}
          src={CURVE_LEFT}
          alt=""
        />
      </section>

      <section className="pick rowc">
        <div className="content rowc">
          <div className="number rowc">
            <span>01</span>
          </div>
          <div className="text col">
            <h1>PICK</h1>
            <p>
              OAO (onchain AI oracle) is powered by opML (optimistic machine
              learning) on Ethereum and brings AI inference onchain. <br />
              <br /> Currently, OAO supports LlaMA 2 and Stable Diffusion.
            </p>
          </div>
          <div className="pick_options rowc">
            <div
              className={`pick_option rowc ${ai == "sd" ? "pick_active" : ""}`}
              onClick={() => navigate("/app/opml/sd")}
            >
              <img src={stable_diffusion} alt="" />
              <span>
                STABLE <br /> DIFFUSION
              </span>
            </div>
            <div
              className={`pick_option rowc ${ai == "llm" ? "pick_active" : ""}`}
              onClick={() => navigate("/app/opml/llm")}
            >
              <img src={llma} alt="" />
              <span>LLMA 2</span>
            </div>
          </div>
        </div>
        <img
          style={{
            position: "absolute",
            right: "0",
            bottom: "0",
            width: "2.5rem",
            transform: "translateY(50%)",
          }}
          src={CURVE_RIGHT}
          alt=""
        />
      </section>
      {(ai == "sd" || ai == "llm") && (
        <section className="prompt rowc">
          <div className="content colc">
            <div className="toprow rowc">
              <div className="number rowc">
                <span>02</span>
              </div>
              <div className="text col">
                <h1>PROMPT</h1>
                <p>
                  Enter your prompt, or get random prompt by clicking "Surprise
                  me". Click "Generate" to initiate request to OAO.
                  <br />
                  <br />
                  then sign tx in your wallet. Wait for a few seconds, and you
                  will see the result.
                </p>
              </div>
              <div className="type col">
                <div className="type_label rowc">
                  <span></span>
                  TYPE...
                </div>
                <span>SURPRISE ME</span>
                <hr />
                <div className="arrow_btn">
                  <Link href="#" color="inherit">
                    <RunningText text={"GENERATE"} />
                  </Link>
                  <GoArrowUpRight color={"black"} />
                </div>
              </div>
            </div>
            <div className="btmrow rowc">
              <img src={defaultopml} alt="" />
              <div className="text col">
                <u>AI ORACLE CONTRACT ADDRESS</u>
                <p>0xf5B0A8Bd667A7aaBc678E8C0BF5EEff82c4357C3</p>
                <br />
                <u>CONTRACT ADDRESS</u>
                <p>0x5d6963003Ad172Fd1D2A2fD18bB3967eA7Aef1a2</p>
              </div>
            </div>
          </div>
          <img
            style={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "2.5rem",
              transform: "translateY(50%)",
            }}
            src={CURVE_LEFT}
            alt=""
          />
        </section>
      )}
      {ai == "llm" && (
        <section className="proof rowc">
          <div className="content rowc">
            <div className="number rowc">
              <span>03</span>
            </div>
            <div className="text col">
              <h1>PROOF</h1>
              <p>
                Click "FINALIZE" button to see the fault proof challenge process
                of opML.
              </p>
              <div className="arrow_btn">
                <Link href="#" color="inherit">
                  <RunningText text={"FINALIZE"} />
                </Link>
                <GoArrowUpRight color={"black"} />
              </div>
            </div>
            <div className="proof_text col">
              challenge ID: <br />
              Challenger Respond: <br />
              Checkpoints [starting point/unanimous agreement, disagreement
              onset, jointly verified consistency checkpoint]: <br />
              root:
              <br /> <br />
              Submitter Respond:
              <br />
              checkpoints:
              <br />
              <br />
              root:
            </div>
          </div>
          <img
            style={{
              position: "absolute",
              left: "0",
              bottom: "0",
              width: "2.5rem",
              transform: "translateY(50%)",
            }}
            src={CURVE_LEFT}
            alt=""
          />
        </section>
      )}

      <section className={`ethereum rowc`}>
        <div className="content colc">
          <h3>OPML IS ALL YOU NEED</h3>
          <h1>
            Run a 13B ML Model <br /> in Ethereum
          </h1>
          <p>
            Hyper Oracle’s zkOracle enables trustless arbitrary compute,
            unlocking onchain AI and ML. We present opML, a flexible and
            performant approach until we launch zkML...
          </p>
          <br />
          <div className="arrow_btn">
            <Link href="#" color="inherit">
              <RunningText text={"READ MORE"} />
            </Link>
            <GoArrowUpRight color={"white"} />
          </div>
        </div>
        <div
          className="eth_circle"
          ref={btmCircleRef}
          style={{ opacity: isIntersect ? "0.1" : "0" }}
        >
          <Circle entranceAnimation={false} color={"black"} />
        </div>
      </section>
      <FooterBar style={{ marginTop: "auto" }} />
    </>
  );
}

export default OAO;
