import {actionTypesConstructor, dispatchAction} from "../utils";
import {combineReducers} from "redux";
import {FETCH_DATA, POST_DATA} from "../middlewares/api";
import url, {baseUrl} from "../../utils/url";
import {schema, testNets} from "./entities/poc";
import {ethers, providers, utils as etherUtils} from "ethers";
import {instantiate} from "../../bundle";
import {Buffer} from 'buffer';
import BN from "bn.js";
import Web3 from "web3";
import {fromHexString, toHexString, trimPrefix} from "../utils/common/utils";
import {
    formatHexStringInput,
    formatIntInput,
    formatVarLenInput,
    genStreamAndMatchedEventOffsets,
    rlpDecodeAndEventFilter
} from "../utils/common/api_helper";
import {exportFuncs} from "../utils/common/bundle_local";
import {getBlockByNumber, getRawReceipts} from "../utils/common/ethers_helper";
import {Md5} from "ts-md5";
import {get, post} from "../../utils/requests";
import {taskDispatcherAddress} from "../../addresses";
import {changeNetwork} from "../utils/wallet_helper";
import {types as walletTypes} from "./wallet";
import {types as asEditorTypes} from "./asEditor";
import {LOGS} from "./entities/asEditor";
import {
    dspHub,
    executeOnDataPrep,
    proveInputGenOnDataPrep,
    TaskDispatch,
    utils,
    CLEYaml, verify, getVerifyProofParamsByTaskID,
} from "@hyperoracle/cle-api-test";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
    CONFIG_CUSTOMIZED_WASM: "POC/CONFIG_CUSTOMIZED_WASM",

    CLEAR_STUDIO_SETUP_STATE_PROVE: "POC/CLEAR/STUDIO_SETUP_STATE_PROVE",

    UPDATE_SOURCE_CONTRACT_ADDRESS: "POC/UPDATE/SOURCE_CONTRACT_ADDRESS",
    UPDATE_SOURCE_EVENT_ABI: "POC/UPDATE/SOURCE_EVENT_ABI",
    UPDATE_TX_HASH: "POC/UPDATE/TX_HASH",

    CONFIG_MD5: "POC/CONFIG/CONFIG_MD5",
    CONFIG_POC_INFO: "POC/CONFIG/CONFIG_POC_INFO",
    CONFIG_PROVE: "POC/CONFIG/CONFIG_PROVE",
    CONFIG_EXECUTE_STATE_RESULT_TITLES: "POC/CONFIG/CONFIG_EXECUTE_STATE_RESULT_TITLES",
    SET_EXECUTED_STATE_RESULT_VALUES: "POC/SET/SET_EXECUTED_STATE_RESULT_VALUES",

    CONFIGURED_MD5: actionTypesConstructor(
        "POC/FETCH/CONFIGURED_MD5_REQUEST",
        "POC/FETCH/CONFIGURED_MD5_SUCCESS",
        "POC/FETCH/CONFIGURED_MD5_FAILURE"
    ),

    NEW_WASM_IMAGE: actionTypesConstructor(
        "POC/SETUP/NEW_WASM_IMAGE_REQUEST",
        "POC/SETUP/NEW_WASM_IMAGE_SUCCESS",
        "POC/SETUP/NEW_WASM_IMAGE_FAILURE"
    ),

    CHECK_IMAGE_STATUS: actionTypesConstructor(
        "POC/SETUP/CHECK_IMAGE_STATUS_REQUEST",
        "POC/SETUP/CHECK_IMAGE_STATUS_SUCCESS",
        "POC/SETUP/CHECK_IMAGE_STATUS_FAILURE"
    ),

    DEPLOY_WASM_IMAGE: actionTypesConstructor(
        "POC/DEPLOY/WASM_IMAGE_REQUEST",
        "POC/DEPLOY/WASM_IMAGE_SUCCESS",
        "POC/DEPLOY/WASM_IMAGE_FAILURE"
    ),

    CHECK_DEPLOY_WASM_IMAGE_STATUS: actionTypesConstructor(
        "POC/DEPLOY/CHECK_WASM_IMAGE_REQUEST",
        "POC/DEPLOY/CHECK_WASM_IMAGE_SUCCESS",
        "POC/DEPLOY/CHECK_WASM_IMAGE_FAILURE"
    ),

    CONFIGURED_PROVE: actionTypesConstructor(
        "POC/FETCH/CONFIGURED_PROVE_REQUEST",
        "POC/FETCH/CONFIGURED_PROVE_SUCCESS",
        "POC/FETCH/CONFIGURED_PROVE_FAILURE"
    ),

    PROVE_WASM_IMAGE: actionTypesConstructor(
        "POC/PROVE/PROVE_WASM_IMAGE_REQUEST",
        "POC/PROVE/PROVE_WASM_IMAGE_SUCCESS",
        "POC/PROVE/PROVE_WASM_IMAGE_FAILURE"
    ),

    CHECK_PROVE_WASM_IMAGE_STATUS: actionTypesConstructor(
        "POC/PROVE/CHECK_PROVE_WASM_IMAGE_STATUS_REQUEST",
        "POC/PROVE/CHECK_PROVE_WASM_IMAGE_STATUS_SUCCESS",
        "POC/PROVE/CHECK_PROVE_WASM_IMAGE_STATUS_FAILURE"
    ),

    EXECUTE_WASM_MAPPING: actionTypesConstructor(
        "POC/EXECUTE/EXECUTE_WASM_MAPPING_REQUEST",
        "POC/EXECUTE/EXECUTE_WASM_MAPPING_SUCCESS",
        "POC/EXECUTE/EXECUTE_WASM_MAPPING_FAILURE"
    ),

    VERIFY_CONTRACT: actionTypesConstructor(
        "POC/VERIFY/VERIFY_CONTACT_REQUEST",
        "POC/VERIFY/VERIFY_CONTACT_SUCCESS",
        "POC/VERIFY/VERIFY_CONTACT_FAILURE",
    ),

    DEPLOYED_IMAGE: actionTypesConstructor(
        "POC/GET/DEPLOYED_IMAGE_REQUEST",
        "POC/GET/DEPLOYED_IMAGE_SUCCESS",
        "POC/GET/DEPLOYED_IMAGE_FAILURE",
    )
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
    isFetchingConfiguredMD5: false,
    isAddingNewWasmImageRequesting: false,
    isCheckingWasmStatus: false,
    isDeployingWasmImageRequesting: false,
    isCheckingDeployWasmImageStatus: false,
    isFetchingConfiguredProve: false,
    isProvingWasmImage: false,
    isCheckingProveWasmStatus: false,
    isVerifying: false,
    isGettingDeployedImageRequesting: false,
    isExecutingWasmMapping: false
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export function arrayToFile(array) {
    return new File( [array], "poc_module.wasm", {
        type: "application/wasm",
        lastModified: Date.now()
    });
}

export const actions = {
    cleanStudioSetupStateProve: ()=>{
        return async (dispatch, getState) => {
            console.log("cleanStudioSetupStateProve: cleaning...")
            await dispatch({
                type: types.CLEAR_STUDIO_SETUP_STATE_PROVE
            })
        }
    },
    configCustomizedWasm: (wasmUnit8Array)=>{
        return async (dispatch, getState) => {
            await dispatch({
                type: types.CONFIG_CUSTOMIZED_WASM,
                response: wasmUnit8Array
            })
        }
    },
    configPOCInfo: ( blockNumber, yaml, defaultExecuteResult, defaultProveResult)=>{
        return async (dispatch, getState) => {
            await dispatch({
                type: types.CONFIG_POC_INFO,
                response: {
                    blockNumber,
                    yaml,
                    defaultExecuteResult,
                    defaultProveResult,
                }
            })
        }
    },
    configExecuteStateResultTitles: (titles)=>{
        return async (dispatch, getState) => {
            await dispatch({
                type: types.CONFIG_EXECUTE_STATE_RESULT_TITLES,
                response: titles
            })
        }
    },
    setExecutedStateResult: (values)=>{
        return async (dispatch, getState) => {
            await dispatch({
                type: types.SET_EXECUTED_STATE_RESULT_VALUES,
                response: values
            })
        }
    },
    fetchHistoryMD5: ()=>{
        return async (dispatch, getState) => {
           const endpoint = url.fetchConfiguredMD5();
           return await dispatch(
               dispatchAction(
                   FETCH_DATA,
                   types.CONFIGURED_MD5.all(),
                   endpoint,
                   schema
               )
           )
        }
    },
    configSelectedMD5: (md5)=>{
        return async (dispatch, getState) => {
            const image = await checkMD5HasBeenAdded(md5);
            if(image){
                return await dispatch(
                    {
                        type: types.CONFIG_MD5,
                        response: image
                    }
                )
            }
        }
    },
    addNewWasmImage: ()=>{
        return async (dispatch, getState) => {
            const md5 = convertToMd5(getState().entities.poc.customizedWasmUint8array.uint8array).toUpperCase();

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {content: LOGS.setup.request, prefix: LOGS.prefix}
            })
            // check if the md5 has been added
            const image = await checkMD5HasBeenAdded(md5);
            if(image){

                return await dispatch(
                    {
                        type: types.CONFIG_MD5,
                        response: image
                    }
                )
            }

            const desiredNetworkId = getState().entities.poc.configInfo.network;
            try {
                const response = await changeNetwork(desiredNetworkId);
                await dispatch({
                    type: walletTypes.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                return await dispatch(
                    {
                        type: types.NEW_WASM_IMAGE.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }

            await dispatch(
                {
                    type: types.NEW_WASM_IMAGE.request(),
                }
            )

            let userAddress =  getState().entities.wallet.address.toLowerCase();
            const directoryName = `${userAddress}-${md5}`;
            const wasm = getState().entities.asEditor.moduleWasm;
            const wasmFile = new File([ new Blob([wasm], {type: "application/wasm"})], "zkgraph.wasm");
            const formData = new FormData();
            formData.append("",wasmFile);


            const endpoint = url.uploadWasm2Td();

            const taskDispatcher = await taskDispatcherContract();
            const txhash = await post(endpoint, formData).then(
                async (response) => {
                    const tx = await taskDispatcher.setup(response.filename, 22);
                    await tx.wait();
                    console.log(tx.hash);
                    return tx.hash;

                },(error)=>{
                    Promise.reject(error);
                    throw error;
                }).catch(async error => {
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {content: `[${LOGS.setup.failure}]${error.message}`, prefix: LOGS.prefix}
                })
                return await dispatch(
                    {
                        type: types.NEW_WASM_IMAGE.failure(),
                        error: error.error,
                        code: error.code,
                        message: error.message,
                    }
                )
            });

            if(txhash){
                try{
                    const response =  await taskDispatcher.queryTask(txhash);
                    console.log(response)
                    return await dispatch(
                        {
                            type: types.NEW_WASM_IMAGE.success(),
                            response: {
                                md5,
                                id: response.task.id
                            }
                        }
                    )
                }catch (error){
                    await dispatch({
                        type: asEditorTypes.PUSH_LOG,
                        payload: {content: `[${LOGS.setup.failure}]${error.message}`, prefix: LOGS.prefix}
                    })
                    return await dispatch(
                        {
                            type: types.NEW_WASM_IMAGE.failure(),
                            error: error.error,
                            code: error.code,
                            message: error.message,
                        }
                    )
                }
            }else{
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {content: `[${LOGS.setup.failure}]Couldn't get tx hash`, prefix: LOGS.prefix}
                })
                return await dispatch(
                    {
                        type: types.NEW_WASM_IMAGE.failure(),
                        error: "Couldn't get tx hash",
                        code:"Couldn't get tx hash",
                        message: "Couldn't get tx hash",
                    }
                )
            }
        }
    },
    fetchWasmImageStatus: ()=>{
        return async (dispatch, getState) =>{
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            console.log(`fetchWasmImageStatus/setup: ${getState().entities.poc.customizedWasmUint8array.setup.status}`);


            if(md5 !== "" &&  !["Fail", "Done"].includes(getState().entities.poc.customizedWasmUint8array.setup.status)){
                const endpoint = url.checkWasmImageStatus(md5, "Setup")
                const res =  await dispatch(
                    dispatchAction(
                        FETCH_DATA,
                        types.CHECK_IMAGE_STATUS.all(),
                        endpoint,
                        schema
                    )
                );

                const setupStatus = res.response.result.data[0].status;
                if(res.type ===  types.CHECK_IMAGE_STATUS.success() && setupStatus === "Done" && !getState().poc.data.isCheckingWasmStatus){
                    await dispatch({
                        type: asEditorTypes.PUSH_LOG,
                        payload: {content: `[${LOGS.setup.success}]MD5: ${md5}`, prefix: ""}
                    });
                }else if(res.type ===  types.CHECK_IMAGE_STATUS.success() && setupStatus === "Fail"){
                    await dispatch({
                        type: asEditorTypes.PUSH_LOG,
                        payload: {content: `[${LOGS.setup.failure}]MD5: ${md5}`, prefix: ""}
                    });
                }
            }else{
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {content: `[${LOGS.setup.failure}]md5 not found`, prefix: LOGS.prefix}
                })
                return dispatch({
                    type: types.CHECK_IMAGE_STATUS.failure(),
                    message: "md5 not found"
                })
            }
        }
    },
    deployWasmImage: (chainId)=>{
        return async (dispatch, getState) => {

            const deployChain = testNets.filter(net => net.value === chainId)[0];
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;

            try {
                const response = await changeNetwork(deployChain.value);
                await dispatch({
                    type: walletTypes.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                return await dispatch(
                    {
                        type: types.DEPLOY_WASM_IMAGE.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }

            await dispatch(
                {
                    type: types.DEPLOY_WASM_IMAGE.request(),
                }
            );

            const taskDispatcher = await taskDispatcherContract();
            const tx = await taskDispatcher.deploy(md5,deployChain.value, {
                value: ethers.utils.parseEther("0.005"),
            });

            await tx.wait();
            console.log(tx.hash);
            if(tx.hash){
                await get({
                    url: `https://zkwasm.hyperoracle.io/td/task?txhash=${tx.hash}`,
                    isProtected: false,
                }).then(
                    async (response)=>{
                        console.log(response,response.task)
                        return await dispatch(
                            {
                                type: types.DEPLOY_WASM_IMAGE.success(),
                                payload: {
                                    chain_id: deployChain.value
                                },
                                response: {
                                    id: response.task.id
                                }
                            }
                        )
                    },
                    (error)=> {console.log(error); Promise.reject(error)}
                ).catch(async error=>{
                    return await dispatch(
                        {
                            type: types.DEPLOY_WASM_IMAGE.failure(),
                            error: error.error,
                            code: error.code,
                            message: error.message,
                        }
                    )
                })
            }else{
                return await dispatch(
                    {
                        type: types.DEPLOY_WASM_IMAGE.failure(),
                        error: "Couldn't get tx hash",
                        code:"Couldn't get tx hash",
                        message: "Couldn't get tx hash",
                    }
                )
            }
        }
    },
    getDeployedWasmImage: () => {
        return async (dispatch, getState) => {
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            const endpoint = url.searchImageURL(md5)

            return await dispatch(
                dispatchAction(
                    FETCH_DATA,
                    types.DEPLOYED_IMAGE.all(),
                    endpoint,
                    schema
                )
            )
        }
    },
    checkDeployWasmImageStatus: ()=>{
        return async (dispatch, getState) => {
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            const endpoint = url.checkWasmImageStatus(md5, "Deploy");
            console.log(`fetchWasmImageStatus/deploy: ${getState().entities.poc.customizedWasmUint8array.deploy.status}`);
            return await dispatch(
                dispatchAction(
                    FETCH_DATA,
                    types.CHECK_DEPLOY_WASM_IMAGE_STATUS.all(),
                    endpoint,
                    schema
                )
            )
        }
    },
    configSelectedProve: (proveId)=>{
        return async (dispatch, getState) => {
            return await dispatch(
                {
                    type: types.CONFIG_PROVE,
                    response: {id: proveId}
                }
            )
        }
    },
    fetchHistoryProve: (md5)=>{
        return async (dispatch, getState) => {
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            const endpoint = url.checkWasmImageStatus(md5, "Prove");
            return await dispatch(
                dispatchAction(
                    FETCH_DATA,
                    types.CONFIGURED_PROVE.all(),
                    endpoint,
                    schema
                )
            )
        }
    },
    proveWasmImage: ()=>{
        return async (dispatch, getState) => {
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            const pvt = getState().entities.poc.customizedWasmUint8array.execute.pvt;
            const pub = getState().entities.poc.customizedWasmUint8array.execute.pub;

            const desiredNetworkId = getState().entities.poc.configInfo.network;

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: LOGS.prove.request,
                    prefix: LOGS.prefix
                }
            });

            try {
                const response = await changeNetwork(desiredNetworkId);
                await dispatch({
                    type: walletTypes.WALLET_CONNECT,
                    payload: {
                        address: response.userAddress,
                        chain: response.chainId
                    }
                })
            } catch (error) {
                console.error('Network change failed:', error);
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.prove.failed}]Network change failed`,
                        prefix: LOGS.prefix
                    }
                });
                return await dispatch(
                    {
                        type: types.PROVE_WASM_IMAGE.failure(),
                        error: error,
                        message: "Network change failed"
                    }
                )
            }

            await dispatch(
                {
                    type: types.PROVE_WASM_IMAGE.request(),
                }
            );

            const taskDispatcher = await taskDispatcherContract();
            const tx = await taskDispatcher.prove(md5,pvt, pub);
            await tx.wait();
            console.log(tx.hash);
            if(tx.hash){
                try{
                    const response =  await taskDispatcher.queryTask(tx.hash);
                    console.log(response)
                    return await dispatch(
                        {
                            type: types.PROVE_WASM_IMAGE.success(),
                            response: {
                                id: response.task.id
                            }
                        }
                    );
                }catch (error){
                    await dispatch({
                        type: asEditorTypes.PUSH_LOG,
                        payload: {
                            content: `[${LOGS.prove.failed}]${error.message}`,
                            prefix: LOGS.prefix
                        }
                    });
                    return await dispatch(
                        {
                            type: types.PROVE_WASM_IMAGE.failure(),
                            error: error.error,
                            code: error.code,
                            message: error.message,
                        }
                    )
                }
            }else{
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.prove.failed}]Couldn't get tx hash`,
                        prefix: LOGS.prefix
                    }
                });
                return await dispatch(
                    {
                        type: types.PROVE_WASM_IMAGE.failure(),
                        error: "Couldn't get tx hash",
                        code:"Couldn't get tx hash",
                        message: "Couldn't get tx hash",
                    }
                )
            }
        }
    },
    checkWasmImageStatus: ()=>{
        return async (dispatch, getState) =>{
            const md5 = getState().entities.poc.customizedWasmUint8array.md5;
            const endpoint = url.checkWasmImageStatus(md5, "Prove");
            const proveTaskId = getState().entities.poc.customizedWasmUint8array.prove.id;
            if(proveTaskId === ""){
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.prove.failed}] prove id not found`,
                        prefix: ""
                    }
                });
                return await dispatch(
                    {
                        type: types.CHECK_PROVE_WASM_IMAGE_STATUS.failure(),
                        error: "Prove id not found",
                        message: "Prove id not found"
                    }
                )
            }
            console.log(`fetchWasmImageStatus/prove: ${getState().entities.poc.customizedWasmUint8array.prove.status}`);

            const res = await dispatch(
                dispatchAction(
                    FETCH_DATA,
                    types.CHECK_PROVE_WASM_IMAGE_STATUS.all(),
                    endpoint,
                    schema
                )
            );
            const proveStatus = res.response.result.data.filter(item => item._id.$oid === proveTaskId)[0].status;
            if(res.type === types.CHECK_PROVE_WASM_IMAGE_STATUS.success() && proveStatus === "Done" && !getState().poc.data.isCheckingProveWasmStatus){
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.prove.success}]Prove ID: ${proveTaskId}`,
                        prefix: ""
                    }
                });
            }else if(res.type === types.CHECK_PROVE_WASM_IMAGE_STATUS.failure() && proveStatus === "Fail"){
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.prove.failed}]Prove ID: ${proveTaskId}`,
                        prefix: ""
                    }
                });
            }
        }
    },
    updateSourceContractAddress: (address)=>{
        return async (dispatch, getState) =>{
            return await dispatch(
                {
                    type: types.UPDATE_SOURCE_CONTRACT_ADDRESS,
                    payload: address
                }
            )
        }
    },
    updateSourceEventAbi: (eventName)=>{
        return async (dispatch, getState) =>{
            return await dispatch(
                {
                    type: types.UPDATE_SOURCE_EVENT_ABI,
                    payload: eventName
                }
            )
        }
    },
    updateTxHash: (hash)=>{
        return async (dispatch, getState) =>{
            return await dispatch(
                {
                    type: types.UPDATE_TX_HASH,
                    payload: hash
                }
            )
        }
    },
    executeWasmMapping: ()=>{
        return async (dispatch, getState) =>{

            await dispatch({
                type: types.EXECUTE_WASM_MAPPING.request(),
            });

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: `${LOGS.execute.request} ${getState().entities.poc.customizedWasmUint8array.execute.txHash}`,
                    prefix: LOGS.prefix
                }
            });

            const blockId = parseInt(getState().entities.poc.customizedWasmUint8array.execute.txHash);
            try{
                const cleYaml = CLEYaml.fromYamlContent(getState().entities.asEditor["src/zkgraph.yaml"]);
                console.log("zkgraphYaml", cleYaml)
                const jsonRpcProvider = testNets.find((net)=>net.label.toLowerCase() === cleYaml.dataSources[0].network.toLowerCase()).jsonRpcProvider;
                console.log("jsonRpcProvider", jsonRpcProvider)
                const wasmUint8Array =  getState().entities.poc.customizedWasmUint8array.uint8array;
                const cleExecutable = {wasmUint8Array, cleYaml};

                const dsp = dspHub.getDSPByYaml(cleExecutable.cleYaml, {'isLocal': false});
                const execParams = dsp.toExecParams(
                    {
                        provider: new ethers.providers.JsonRpcProvider(jsonRpcProvider),
                        blockId,
                    },
                );
                const prepareParams = await dsp.toPrepareParamsFromExecParams(execParams);
                let dataPrep = await dsp.prepareData(cleYaml, prepareParams);
                const state = await executeOnDataPrep(cleExecutable, dataPrep);
                let stateStr = utils.toHexString(state);
                console.log("stateStr", stateStr)
                dataPrep = dsp.toProveDataPrep(dataPrep, stateStr);
                const [privateInputStr, publicInputStr] = proveInputGenOnDataPrep(cleExecutable, dataPrep);

                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[${LOGS.execute.success}]state: ${stateStr}`,
                        prefix: ""
                    }
                })

                return await dispatch(
                    {
                        type: types.EXECUTE_WASM_MAPPING.success(),
                        response: {
                            state: stateStr, privateInputStr: privateInputStr, publicInputStr: publicInputStr
                        }
                    }
                )

            }catch (e){
                await dispatch({
                    type: types.EXECUTE_WASM_MAPPING.failure(),
                    response: {error: e.message},
                });

                return await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `${LOGS.execute.failed} ${e.message}`,
                        prefix: LOGS.prefix
                    }
                });
            }
        }
    },
    _executeWasmMapping: (chainId)=>{
        return async (dispatch, getState) =>{
            const blockid = parseInt(getState().entities.poc.customizedWasmUint8array.execute.txHash);
            await dispatch({
                type: types.EXECUTE_WASM_MAPPING.request(),
            })

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: `${LOGS.execute.request} ${getState().entities.poc.customizedWasmUint8array.execute.txHash}`,
                    prefix: LOGS.prefix
                }
            })

            const wasmUint8Array =  getState().entities.poc.customizedWasmUint8array.uint8array;
            const sourceContractAddress = getState().entities.poc.configInfo.sourceContractAddress; // ethers.utils.getAddress
            const sourceEventName = getState().entities.poc.configInfo.sourceEventABI;
            const network = getState().entities.poc.configInfo.network;
            const jsonRpcProvider = getState().entities.poc.configInfo.jsonRpcProvider[chainId ? chainId : network];
            console.log(jsonRpcProvider, chainId, network);
            if(!blockid){
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[LOGS.execute.failure]Inputted block hash / id is invalid`,
                        prefix: LOGS.prefix
                    }
                })
                return  await dispatch({
                    type: types.EXECUTE_WASM_MAPPING.failure(),
                    response: {
                        error: "Inputted block hash / id is invalid",
                    }
                })
            }

            const edefs = [sourceEventName];
            const source_esigs = edefs.map((ed) =>
                ethers.utils.keccak256(ethers.utils.toUtf8Bytes(ed)),
            );

            const _jsonRpcProvider = new providers.JsonRpcProvider(jsonRpcProvider);
            const rawreceiptList = await getRawReceipts(_jsonRpcProvider, blockid);
            console.log("rawreceiptList: ", rawreceiptList)
            const [filteredRawReceiptList, filteredEventList] = rlpDecodeAndEventFilter(
                rawreceiptList,
                fromHexString(sourceContractAddress),
                source_esigs.map((esig) => fromHexString(esig)),
            );

            let [rawReceipts, matchedEventOffsets] = genStreamAndMatchedEventOffsets(
                filteredRawReceiptList,
                filteredEventList,
            );

            console.log("rawReceipts: ", rawReceipts)
            console.log("matchedEventOffsets: ", matchedEventOffsets)

            matchedEventOffsets = Uint32Array.from(matchedEventOffsets);

            for (let i in filteredEventList) {
                for (let j in filteredEventList[i]) {
                    filteredEventList[i][j].prettyPrint("\tTx[" + i + "]Event[" + j + "]", false);
                }
            }

            const {asmain, __as_start} = await exportFuncs(wasmUint8Array);
            let state = ""
            try{
                __as_start && __as_start();
                state = asmain(rawReceipts, matchedEventOffsets);
            }catch (e) {
                console.log("end: error: ", e.message)
                await dispatch({
                    type: asEditorTypes.PUSH_LOG,
                    payload: {
                        content: `[LOGS.execute.failure]${e.message}`,
                        prefix: LOGS.prefix
                    }
                })
                return  await dispatch({
                    type: types.EXECUTE_WASM_MAPPING.failure(),
                    response: {
                        error: e.message
                    }
                })
            }
            const simpleblock = await _jsonRpcProvider.getBlock(blockid);
            const block = await getBlockByNumber(_jsonRpcProvider, simpleblock.number)

            const publicInputStr =
                formatIntInput(parseInt(block.number)) +
                formatHexStringInput(block.hash) +
                formatVarLenInput(trimPrefix(toHexString(state), "0x"))

            const privateInputStr =
                formatVarLenInput(toHexString(rawReceipts)) +
                formatHexStringInput(block.receiptsRoot)


            console.log("outputstate: ", toHexString(state));

            await dispatch({
                type: asEditorTypes.PUSH_LOG,
                payload: {
                    content: `[${LOGS.execute.success}]state: ${toHexString(state)}`,
                    prefix: ""
                }
            })

            return await dispatch(
                {
                    type: types.EXECUTE_WASM_MAPPING.success(),
                    response: {
                        state: toHexString(state), privateInputStr: privateInputStr, publicInputStr: publicInputStr
                    }
                }
            )
        }
    },
    verifyContractAtPoc: (deployChain)=>{
        return async (dispatch, getState) => {

            dispatch({
                type: types.VERIFY_CONTRACT.request(),
                response: "SENDING"
            })

            const wasmUint8Array =  getState().entities.poc.customizedWasmUint8array.uint8array;
            const cleYaml = CLEYaml.fromYamlContent(getState().entities.asEditor["src/zkgraph.yaml"]);
            const ZkwasmProviderUrl = 'https://rpc.zkwasmhub.com:8090';
            const proveTaskId = getState().entities.poc.customizedWasmUint8array.prove.id;
            const jsonRpcProvider = testNets.find((net)=>net.label.toLowerCase() === cleYaml.dataSources[0].network.toLowerCase()).jsonRpcProvider;
            const proofParams = await getVerifyProofParamsByTaskID(proveTaskId, ZkwasmProviderUrl);


            try {
                await verify(
                    { wasmUint8Array, cleYaml},
                    proofParams,
                    jsonRpcProvider,
                );
            } catch (error) {
                console.log(error.message);
                console.log("verify failed");
                return await dispatch({
                    type: types.VERIFY_CONTRACT.failure(),
                    response: false
                })
            }

            console.log("verify success");
            return await dispatch({
                type: types.VERIFY_CONTRACT.success(),
                response: true
            })

        }
    }
}


/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const data = (state = initialState, action) => {
    switch (action.type) {
        case types.CONFIGURED_MD5.request():
            return {...state, isConfiguringMd5: true}
        case types.CONFIGURED_MD5.success():
            return {...state, isConfiguringMd5: false}
        case types.CONFIGURED_MD5.failure():
            return {...state, isConfiguringMd5: false}
        case types.NEW_WASM_IMAGE.request():
            return {...state, isAddingNewWasmImageRequesting: true}
        case types.NEW_WASM_IMAGE.success():
            return {...state, isAddingNewWasmImageRequesting: false}
        case types.NEW_WASM_IMAGE.failure():
            return {...state, isAddingNewWasmImageRequesting: false}
        case types.CHECK_IMAGE_STATUS.request():
            return {...state, isCheckingWasmStatus: true}
        case types.CHECK_IMAGE_STATUS.success():
            return {...state, isCheckingWasmStatus: false}
        case types.CHECK_IMAGE_STATUS.failure():
            return {...state, isCheckingWasmStatus: false}
        case types.DEPLOY_WASM_IMAGE.request():
            return {...state, isDeployingWasmImageRequesting: true}
        case types.DEPLOY_WASM_IMAGE.success():
            return {...state, isDeployingWasmImageRequesting: false}
        case types.DEPLOY_WASM_IMAGE.failure():
            return {...state, isDeployingWasmImageRequesting: false}
        case types.CHECK_DEPLOY_WASM_IMAGE_STATUS.request():
            return {...state, isCheckingDeployWasmImageStatus: true}
        case types.CHECK_DEPLOY_WASM_IMAGE_STATUS.success():
            return {...state, isCheckingDeployWasmImageStatus: false}
        case types.CHECK_DEPLOY_WASM_IMAGE_STATUS.failure():
            return {...state, isCheckingDeployWasmImageStatus: false}
        case types.CONFIGURED_PROVE.request():
            return {...state, isFetchingConfiguredProve: true}
        case types.CONFIGURED_PROVE.success():
            return {...state, isFetchingConfiguredProve: false}
        case types.CONFIGURED_PROVE.failure():
            return {...state, isFetchingConfiguredProve: false}
        case types.PROVE_WASM_IMAGE.request():
            return {...state, isProvingWasmImage: true}
        case types.PROVE_WASM_IMAGE.success():
            return {...state, isProvingWasmImage: false}
        case types.PROVE_WASM_IMAGE.failure():
            return {...state, isProvingWasmImage: false}
        case types.CHECK_PROVE_WASM_IMAGE_STATUS.request():
            return {...state, isCheckingProveWasmStatus: true}
        case types.CHECK_PROVE_WASM_IMAGE_STATUS.success():
            return {...state, isCheckingProveWasmStatus: false}
        case types.CHECK_PROVE_WASM_IMAGE_STATUS.failure():
            return {...state, isCheckingProveWasmStatus: false}
        case types.VERIFY_CONTRACT.request():
            return {...state, isVerifying: true}
        case types.VERIFY_CONTRACT.success():
            console.log(action)
            return {...state, isVerifying: false}
        case types.VERIFY_CONTRACT.failure():
            console.log(action)
            return {...state, isVerifying: false}
        case types.DEPLOYED_IMAGE.request():
            return {...state, isDeployingWasmImageRequesting: true}
        case types.DEPLOYED_IMAGE.success():
            return {...state, isDeployingWasmImageRequesting: false}
        case types.DEPLOYED_IMAGE.failure():
            return {...state, isDeployingWasmImageRequesting: false}
        case types.EXECUTE_WASM_MAPPING.request():
            return {...state, isExecutingWasmMapping: true}
        case types.EXECUTE_WASM_MAPPING.success():
            return {...state, isExecutingWasmMapping: false}
        case types.EXECUTE_WASM_MAPPING.failure():
            return {...state, isExecutingWasmMapping: false}
        default:
            return state;
    }
}


const reducer = combineReducers({data})
export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getPOCFetchingStatus = (state) => {
    return state.poc.data;
}


async function geLastLog(provider, address, txhash, eventName) {
    let lastLog = null;
    let expectTopic = etherUtils.id(eventName);
    const receipt = await provider.getTransactionReceipt(txhash);
    console.log("receipt: ", receipt)
    for (let j = receipt.logs.length - 1; j >= 0; j--) {
        const log = receipt.logs[j];
        const logTopic = log.topics[0];
        console.table({
            "logTopic": logTopic,
            "expectTopic": expectTopic,
            "log.address": log.address,
            "address": address
        })
        if (logTopic === expectTopic && log.address === address) {
            lastLog = log;
            break;
        }
    }

    console.log("lastlog: ", lastLog)
    return lastLog;
}

export function hexToUint8Array(hex) {
    if (hex.startsWith("0x")) {
        hex = hex.substring(2);
    }
    let arr = [];
    for (let i = 0, l = hex.length; i < l; i += 2) {
        arr.push(parseInt(hex.substr(i, 2), 16));
    }
    return new Uint8Array(arr);
}

export function uint8array2str(byteArray) {
    return "0x" + Buffer.from(byteArray).toString('hex');
}

async function callWasm(eventSig, topic1, topic2, topic3, data, wasmUint8Array) {
    const exports = await instantiate(
        await WebAssembly.compileStreaming(fetch(window.URL.createObjectURL(new Blob([wasmUint8Array], {type: "application/wasm"})))),
        {}
    );
    let output = exports.handleEvent(
        hexToUint8Array(eventSig),
        hexToUint8Array(topic1),
        hexToUint8Array(topic2),
        hexToUint8Array(topic3),
        hexToUint8Array(data)
    );

    return uint8array2str(output);
}

function generateInput(eventSig, topic1, topic2, topic3, data, output) {
    if (data.startsWith("0x")) {
        data = data.slice(2);
      }
    let dataLength = data.length / 2;

    if (output.startsWith("0x")) {
        output = output.slice(2);
      }
      let outputLength = output.length / 2;

    let publicinput = new Array(7);
    publicinput[0] = `${eventSig}:bytes-packed`;
    publicinput[1] = `${topic1}:bytes-packed`;
    publicinput[2] = `${topic2}:bytes-packed`;
    publicinput[3] = `${topic3}:bytes-packed`;
    publicinput[4] = `0x${dataLength.toString(16)}:i64`;
    publicinput[5] = `0x${data}:bytes-packed`;
    publicinput[6] = `0x${outputLength.toString(16)}:i64`;
    publicinput[7] = `0x${output}:bytes-packed`;

    return publicinput;
}

// Convert a hex string to a byte array

function hexToBNs(hexString){
    let bytes = new Array(Math.ceil(hexString.length/16));
    for (var i = 0; i < hexString.length; i += 16) {
        bytes[i] = new BN(hexString.slice(i, Math.min(i+16, hexString.length)), 16, 'le');
    }
    return bytes;
}

export function parseArg(input) {
    let inputArray = input.split(":");
    let value = inputArray[0];
    let type = inputArray[1];
    let re1 = new RegExp(/^[0-9A-Fa-f]+$/); // hexdecimal
    let re2 = new RegExp(/^\d+$/); // decimal

    // Check if value is a number
    if(!(re1.test(value.slice(2)) || re2.test(value))) {
        console.log("Error: input value is not an interger number");
        return null;
    }

    // Convert value byte array
    if(type == "i64") {
        let v;
        if(value.slice(0, 2) == "0x") {
            v = new BN(value.slice(2), 16);
        } else {
            v = new BN(value);
        }
        return [v];
    } else if(type == "bytes" || type == "bytes-packed") {
        if(value.slice(0, 2) != "0x") {
            console.log("Error: bytes input need start with 0x");
            return null;
        }
        let bytes = hexToBNs(value.slice(2));
        return bytes;
    } else {
        console.log("Unsupported input data type: %s", type);
        return null;
    }
}

export function parseArgs(raw) {
    let parsedInputs = new Array();
    for (var input of raw) {
        input = input.trim();
        if (input!=="") {
            let args = parseArg(input);
            if (args!=null) {
                parsedInputs.push(args);
            } else {
                throw Error(`invalid args in ${input}`);
            }
        }
    }
    return parsedInputs.flat();
}

export function bytesToBN(data) {
    let chunksize = 64;
    let bns = [];
    for (let i = 0; i < data.length; i += 32) {
        const chunk = data.slice(i, i + 32);
        let a = new BN(chunk,'le');
        bns.push(a);
        // do whatever
    }
    return bns;
}

export const contract_abi = {
    "contractName": "AggregatorVerifier",
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "contract AggregatorVerifierCoreStep[]",
                    "name": "_steps",
                    "type": "address[]"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256[]",
                    "name": "proof",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "verify_instance",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "aux",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[][]",
                    "name": "target_instance",
                    "type": "uint256[][]"
                }
            ],
            "name": "verify",
            "outputs": [],
            "stateMutability": "view",
            "type": "function",
            "constant": true
        }
    ],
}

export async function signMessage(message) {
    let provider = window.ethereum;
    if (!provider) {
        throw new Error("No provider found!");
    }
    const accounts = await window.ethereum.request({
    method: 'eth_requestAccounts',
    })
    const account = accounts[0];

    const msg = Web3.utils.utf8ToHex(message);
    const msgParams = [msg, account];
    //TODO: type this properly
    const sig = await (provider).request({
        method: "personal_sign",
        params: msgParams,
    });
    return sig;

}

const convertToMd5 = (value) => {
    let md5 = new Md5();
    md5.appendByteArray(value);
    let hash = md5.end();
    if(!hash) return "";
    return hash.toString();
}

const createAddImageSignMessage = (params) => {
    //sign all the fields except the image itself and signature
    let message = "";
    message += params.name;
    message += params.image_md5;
    message += params.user_address;
    message += params.description_url;
    message += params.avator_url;
    message += params.circuit_size;
    return message;
}

const checkMD5HasBeenAdded = async (md5) => {
    const restUrl = baseUrl();
    let res = await fetch(`${restUrl}/image?md5=${md5}`, { method: "GET"})
    if(res.status === 200){
        let image = await res.json();
        console.log("image: ", image)
        return image.result[0];
    }
    return null;
}

const taskDispatcherContract = async () =>{
    if (!window.ethereum) {
        throw new Error("No provider found!");
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    return　new TaskDispatch("https://zkwasm.hyperoracle.io/td/", taskDispatcherAddress(), ethers.utils.parseEther('0.005'), provider, signer);
}
