import { surpriseMeLLMPrompts, surpriseMePrompts } from "./constants";

export function getRandomPrompt(prompt: string): string {
  const randomIndex = Math.floor(Math.random() * surpriseMePrompts.length);
  const randomPrompt = surpriseMePrompts[randomIndex];
  if (randomPrompt === prompt) return getRandomPrompt(prompt);
  return randomPrompt;
}

export function getRandomLLMPrompt(prompt: string): string {
  const randomIndex = Math.floor(Math.random() * surpriseMeLLMPrompts.length);
  const randomPrompt = surpriseMeLLMPrompts[randomIndex];
  if (randomPrompt === prompt) return getRandomLLMPrompt(prompt);
  return randomPrompt;
}

// export async function downloadImage(_id, photo) {
//   FileSaver.saveAs(photo, `download-${_id}.jpg`);
//   Toast.fire({
//     icon: "success",
//     title: "successfully downloaded",
//   });
// }
