/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import {types as graphStudioModuleTypes} from "../graphStudio";

const mapping = {
    "GRAPH_NAME":"graphName",
    "DESCRIPTION": "description",
    "PROFILE_IMAGE": "profileImage",
    "INDEXING": "indexing",
    "AUTOMATION": "automation",
    "NETWORK": "network",
    "DESTINATION_CONTRACT_ADDRESS": "destinationContractAddress",
    "DESTINATION_FUNCTION_NAME": "destinationFunctionName"
}

const bountyMapping = {
    "TOTAL_AMOUNT": "totalAmount",
    "REWARD_PER_TRIGGER": "rewardPerTrigger"
}

export const types = {
    GRAPH_STUDIO_CREATION_APPINFO_UPDATE: "APP/GRAPH_STUDIO_CREATION/APPINFO/UPDATE",
    GRAPH_STUDIO_CREATION_BOUNTY_UPDATE: "APP/GRAPH_STUDIO_CREATION/BOUNTY/UPDATE",
}
/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
export type BountyProps = {
    totalAmount?: number | null,
    rewardPerTrigger: number
}

export type IPFSProps = {
    IpfsHash: string | null,
}

export type InitialStateProps = {
    graphName: string | null,
    description: string | null,
    profileImage: string | null,
    appType: "INDEXING" | "AUTOMATION",
    network: "SEPOLIA",
    destinationContractAddress: string | null,
    destinationFunctionName: string | null,
    bounty: BountyProps,
    IPFS: IPFSProps,
}

const initialState: InitialStateProps = {
    graphName: null,
    description: null,
    profileImage: "https://www.hyperoracle.io/static/media/Hyper%20Oracle%20Logo_White.8d58b96fc82ce311ad7ea6bf614ef344.svg",
    appType: "AUTOMATION",
    network: "SEPOLIA",
    destinationContractAddress: null,
    destinationFunctionName: null,
    bounty:{
        totalAmount: 0,
        rewardPerTrigger: 0
    },
    IPFS: {
        IpfsHash: null
    },
}

export const schema = {
    name: "graphStudio",
    id: "id",
};


/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export type AppInfoProps = {
    content: string,
    contentType: "GRAPH_NAME" | "DESCRIPTION" | "PROFILE_IMAGE" | "NETWORK" | "INDEXING" | "AUTOMATION" | "DESTINATION_CONTRACT_ADDRESS" | "DESTINATION_FUNCTION_NAME"
}

export type BountyInfoProps = {
    amount: number,
    bountyType: "TOTAL_AMOUNT" | "REWARD_PER_TRIGGER"
}
export const actions = {
    updateAppCreationInfo: (appInfoProps:AppInfoProps) => {
        return async (dispatch:any, getState:any) => {
            console.log(appInfoProps, "APP_INFO_PROPS")

            await dispatch({
                type: types.GRAPH_STUDIO_CREATION_APPINFO_UPDATE,
                payload: appInfoProps
            })
        }
    },
    updateBountyCreationInfo: (bountyInfoProps: BountyInfoProps) => {
        return async (dispatch: any, getState: any) => {
            await dispatch({
                type: types.GRAPH_STUDIO_CREATION_BOUNTY_UPDATE,
                payload: bountyInfoProps
            })
        }
    },
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case types.GRAPH_STUDIO_CREATION_APPINFO_UPDATE:
            const {content, contentType} : AppInfoProps = action.payload;
            return {...state, [mapping[contentType]]: content}
        case types.GRAPH_STUDIO_CREATION_BOUNTY_UPDATE:
            const {amount, bountyType} : BountyInfoProps = action.payload;
            return {...state, bounty: {...state.bounty, [bountyMapping[bountyType]]: amount}}
        case graphStudioModuleTypes.GRAPH_STUDIO_CREATION_PINATA_UPLOAD.success():
            return {...state, IPFS: {IpfsHash: action.response.IpfsHash}}
        default:
            return state;
    }
}

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getGraphStudio = (state:any) => state.entities.graphStudio;

export default reducer;

