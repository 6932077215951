import {actionTypesConstructor, dispatchAction} from "../utils";
import {ethers, providers} from "ethers";
import {getBlockByNumber, getRawReceipts} from "../utils/common/ethers_helper";
import {
    formatHexStringInput,
    formatIntInput, formatVarLenInput,
    genStreamAndMatchedEventOffsets,
    rlpDecodeAndEventFilter
} from "../utils/common/api_helper";
import {fromHexString, toHexString, trimPrefix} from "../utils/common/utils";
import {exportFuncs} from "../utils/common/bundle_local";
import {signMessage, uint8array2str} from "./poc";
import {combineReducers} from "redux";
import url from "../../utils/url";
import {FETCH_DATA, POST_DATA} from "../middlewares/api";
import {schema} from "./entities/poc";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
// const provider = new providers.JsonRpcProvider("https://eth.llamarpc.com/");

export const types = {
    CASE_BLOCK_NUMBER_CHANGE: 'APP|CASE|CASE_BLOCK_NUMBER_CHANGE',
    CASE_EXECUTE: actionTypesConstructor(
        "APP/CASE/EXECUTE_REQUEST",
        "APP/CASE/EXECUTE_SUCCESS",
        "APP/CASE/EXECUTE_FAILURE"
    ),
    CASE_PROVE: actionTypesConstructor(
        "APP/CASE/PROVE_REQUEST",
        "APP/CASE/PROVE_SUCCESS",
        "APP/CASE/PROVE_FAILURE"
    ),
    CHECK_CASE_PROVE_STATUS: actionTypesConstructor(
        "APP/CASE/CHECK_CASE_PROVE_STATUS_REQUEST",
        "APP/CASE/CHECK_CASE_PROVE_STATUS_SUCCESS",
        "APP/CASE/CHECK_CASE_PROVE_STATUS_FAILURE"
    ),

}


/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
    isCaseExecuting: false,
    isCaseProving: false,
    isCheckingCaseProveStatus: false,
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
    caseBlockNumberChange: (caseName: string, blockNumber: number) => {
        return async (dispatch: any, getState: any) => {
            dispatch({
                type: types.CASE_BLOCK_NUMBER_CHANGE,
                payload: {
                    caseName,
                    blockNumber
                }
            });
        }
    },
    // caseExecute: (caseTag: string)=>{
    //     return async (dispatch: any, getState: any) =>{
    //         await dispatch({
    //             type: types.CASE_EXECUTE.request(),
    //         });
    //         const wasmUint8Array =  getState().entities.cases.cases[caseTag].wasmUint8Array;
    //         const sourceContractAddress = getState().entities.cases.cases[caseTag].sourceContractAddress; // ethers.utils.getAddress
    //         const sourceEventName = getState().entities.cases.cases[caseTag].sourceEventName;
    //         const blockNumber = getState().entities.cases.cases[caseTag].blockNumber;
    //
    //         if(!blockNumber || blockNumber === ""){
    //             return  await dispatch({
    //                 type: types.CASE_EXECUTE.failure(),
    //                 response: {
    //                     error: "Inputted block hash / id is invalid",
    //                 }
    //             })
    //         }
    //
    //         const edefs = [sourceEventName];
    //         const source_esigs = edefs.map((ed) =>
    //             ethers.utils.keccak256(ethers.utils.toUtf8Bytes(ed)),
    //         );
    //
    //         const rawreceiptList = await getRawReceipts(new providers.JsonRpcProvider("https://eth-mainnet.nodereal.io/v1/1659dfb40aa24bbb8153a677b98064d7"), blockNumber);
    //         console.log("rawreceiptList: ", rawreceiptList)
    //         const [filteredRawReceiptList, filteredEventList] = rlpDecodeAndEventFilter(
    //             rawreceiptList,
    //             fromHexString(sourceContractAddress),
    //             source_esigs.map((esig) => fromHexString(esig)),
    //         );
    //
    //         let [rawReceipts, matchedEventOffsets] = genStreamAndMatchedEventOffsets(
    //             filteredRawReceiptList,
    //             filteredEventList,
    //         );
    //
    //         console.log("rawReceipts: ", rawReceipts)
    //         console.log("matchedEventOffsets: ", matchedEventOffsets)
    //
    //         // @ts-ignore
    //         matchedEventOffsets = Uint32Array.from(matchedEventOffsets);
    //
    //         for (let i in filteredEventList) {
    //             for (let j in filteredEventList[i]) {
    //                 filteredEventList[i][j].prettyPrint("\tTx[" + i + "]Event[" + j + "]", false);
    //             }
    //         }
    //
    //         const {asmain, __as_start} = await exportFuncs(wasmUint8Array);
    //         let state = ""
    //         try{
    //            //__as_start();
    //             state = asmain(rawReceipts, matchedEventOffsets);
    //         }catch (e:any) {
    //             console.log("end: error: ", e.message)
    //             return  await dispatch({
    //                 type: types.CASE_EXECUTE.failure(),
    //                 response: {
    //                     error: e.message
    //                 }
    //             })
    //         }
    //         const simpleblock = await provider.getBlock(blockNumber);
    //         const block = await getBlockByNumber(provider, simpleblock.number)
    //
    //         const publicInputStr =
    //             formatIntInput(parseInt(block.number)) +
    //             formatHexStringInput(block.hash) +
    //             formatVarLenInput(trimPrefix(toHexString(state), "0x"))
    //
    //         const privateInputStr =
    //             formatVarLenInput(toHexString(rawReceipts)) +
    //             formatHexStringInput(block.receiptsRoot)
    //
    //            console.log("privateInputStr: ", privateInputStr)
    //             console.log("publicInputStr: ", publicInputStr)
    //         console.log("outputstate: ", toHexString(state));
    //
    //         return await dispatch(
    //             {
    //                 type: types.CASE_EXECUTE.success(),
    //                 response: {
    //                     state: uint8array2str(state),
    //                     privateInputStr: privateInputStr.trim().split(" "),
    //                     publicInputStr: publicInputStr.trim().split(" ")
    //                 }
    //             }
    //         )
    //     }
    // },
    // caseProve: (caseTag: string)=>{
    //     return async (dispatch:any, getState:any) => {
    //         const md5 = getState().entities.cases.cases[caseTag].zkWASMImage;
    //         const pvt = getState().entities.cases.execute.pvt;
    //         const pub = getState().entities.cases.execute.pub;
    //         const endpoint = url.proveWasmImageURL();
    //
    //         // check balance
    //         const {ethBalance} = getState().entities.wallet;
    //         if(ethBalance < 0.001){
    //             return await dispatch(
    //                 {
    //                     type: types.CASE_PROVE.failure(),
    //                     error: "Insufficient balance",
    //                     message: "Insufficient balance, please top up your wallet"
    //                 }
    //             )
    //         }
    //
    //         let signature = await signMessage(JSON.stringify({
    //             user_address: getState().entities.wallet.address.toLowerCase(),
    //             md5,
    //             public_inputs: pub,
    //             private_inputs: pvt
    //         }));
    //
    //         const req = JSON.stringify({
    //             user_address:getState().entities.wallet.address.toLowerCase(),
    //             md5,
    //             public_inputs: pub,
    //             private_inputs: pvt,
    //             signature
    //         });
    //
    //         return await dispatch(
    //             dispatchAction(
    //                 POST_DATA,
    //                 types.CASE_PROVE.all(),
    //                 endpoint,
    //                 null,
    //                 req
    //             )
    //         )
    //     }
    // },
    checkCaseProveStatus: (caseTag: string)=>{
        return async (dispatch:any, getState:any) =>{
            const md5 = getState().entities.cases.cases[caseTag].zkWASMImage;
            const endpoint = url.checkWasmImageStatus(md5, "Prove");
            if(getState().entities.cases.prove.id === ""){
                return await dispatch(
                    {
                        type: types.CHECK_CASE_PROVE_STATUS.failure(),
                        error: "Prove id not found",
                        message: "Prove id not found"
                    }
                )
            }
            console.log(`fetchWasmImageStatus/prove: ${getState().entities.cases.prove.status}`);
            return await dispatch(
                dispatchAction(
                    FETCH_DATA,
                    types.CHECK_CASE_PROVE_STATUS.all(),
                    endpoint,
                    schema
                )
            )
        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const data = (state = initialState, action:any) => {
    switch (action.type) {
        case types.CASE_EXECUTE.request():
            return {...state, isCaseExecuting: true}
        case types.CASE_EXECUTE.success():
            return {...state, isCaseExecuting: false}
        case types.CASE_EXECUTE.failure():
            return {...state, isCaseExecuting: false}
        case types.CASE_PROVE.request():
            return {...state, isCaseProving: true}
        case types.CASE_PROVE.success():
            return {...state, isCaseProving: false}
        case types.CASE_PROVE.failure():
            return {...state, isCaseProving: false}
        case types.CHECK_CASE_PROVE_STATUS.request():
            return {...state, isCheckingCaseProveStatus: true}
        case types.CHECK_CASE_PROVE_STATUS.success():
            return {...state, isCheckingCaseProveStatus: false}
        case types.CHECK_CASE_PROVE_STATUS.failure():
            return {...state, isCheckingCaseProveStatus: false}
        default:
            return state;
    }
}

const reducer = combineReducers({data})
export default reducer;
