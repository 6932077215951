import React, { useEffect } from "react";
import OraLog from "../../assets/ora/logo.svg";
import useWindowSize from "@rooks/use-window-size";
import ParticleImage, {
  ParticleOptions,
  Vector,
  forces,
  ParticleForce,
} from "react-particle-image";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import useScreen from "../../hooks/aboutScreen/useScreen";
import { Z_FIXED } from "zlib";
import "./style.css";

const particleOptions: ParticleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if blue > 50 (range 0-255)
    return pixel.b > 50;
  },
  color: ({ x, y, image }) => "#61dafb",

  mass: () => 100,
  friction: () => 0.1,
  // initialPosition: ({ canvasDimensions }) => {
  //     return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
  // }
};

const motionForce = (
  x: number,
  y: number,
  strength: number = 100
): ParticleForce => {
  return forces.disturbance(x, y, strength);
};

type ParticleComponentProps = {
  setDisplayCoreContent: React.Dispatch<React.SetStateAction<boolean>>;
};

const ParticleComponent = ({ transform, setEnableScroll }: any) => {
  const { SCREEN_SIZE } = useScreen();
  const { innerWidth, innerHeight } = useWindowSize();
  const [entropy, setEntropy] = React.useState(1500);

  let showParticleTimeout: NodeJS.Timeout;

  useEffect(() => {
    showParticleTimeout = setTimeout(() => {
      // setEntropy(1500);
      setEntropy(SCREEN_SIZE.SMALL ? 20 : 80);
      setTimeout(() => {
        setEnableScroll();
      }, 1000);
    }, 2000);

    return () => {
      clearTimeout(showParticleTimeout);
    };
  }, []);

  useEffect(() => {
    if (transform == true) {
      setEntropy(1500);
    }
  }, [transform]);

  return (
    <ParticleImage
      style={{ zIndex: 10 }}
      src={OraLog}
      width={Number(innerWidth)}
      height={Number(innerHeight)}
      scale={SCREEN_SIZE.SMALL ? 1.5 : 6}
      entropy={entropy}
      maxParticles={SCREEN_SIZE.SMALL ? 1000 : 5000}
      particleOptions={{
        ...particleOptions,
        radius: () =>
          SCREEN_SIZE.SMALL ? Math.random() * 1.5 + 1 : Math.random() * 1.5 + 2,
      }}
      mouseMoveForce={motionForce}
      // touchMoveForce={motionForce}
      backgroundColor="transprant"
    />
  );
};

export default ParticleComponent;
