/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import {types} from "../poc";

export const testNets = [
    {
        name: "Sepolia",
        label: "Sepolia",
        value: 11155111,
        expectedEth: 0.005,
        hex: "0xaa36a7",
        jsonRpcProvider: "https://rpc.ankr.com/eth_sepolia"
    },
    {
        name: "Ethereum",
        label: "mainnet",
        value: 1,
        expectedEth: 0.005,
        jsonRpcProvider: "https://rpc.ankr.com/eth"
    },
    {
        name: "Goerli",
        label: "Goerli",
        value: 5,
        expectedEth: 0.5,
        hex: "0x5"
    },
]

const initialState = {
    configInfo:{
        sourceContractAddress: "0xa60ecf32309539dd84f27a9563754dca818b815e",
        sourceEventABI: "Sync(uint112,uint112)",
        network: 11155111,
        jsonRpcProvider: {
            1: "https://rpc.ankr.com/eth",
            11155111: "https://rpc.ankr.com/eth_sepolia",
        }

    },
    customizedWasmUint8array: {
        uint8array: null,
        md5: "",
        configuredMd5: [],
        setup:{
            id: "",
            status: null,
            taskType: null,
            submit_time: null,
            process_started: null,
            process_finished: null,
            user_address: null,
        },
        deploy: {
            11155111: {
                id: "",
                status: null,
                taskType: null,
                submit_time: null,
                process_started: null,
                process_finished: null,
                user_address: null,
                deployedAddress: ""
            },
            5: {
                id: "",
                status: null,
                taskType: null,
                submit_time: null,
                process_started: null,
                process_finished: null,
                user_address: null,
                deployedAddress: ""
            },
        },
        configuredProves: [],
        prove: {
                id: "",
                status: null,
                taskType: null,
                submit_time: null,
                process_started: null,
                process_finished: null,
                user_address: null,
                proof: [],
                instances: [],
                aux: [],
                pub: [],
                pvt: "",
                status_message: null
        },
        execute: {
            stateResult:{
              titles: [],
              values: [],
            },
            txHash: "0x22c87b",
            output: null,
            pub: "",
            pvt: "",
            error: null,
        },
        verify: {
            result: "UNSENT"
        }
    },
}

export const schema = {
    name: "poc",
    id: "id",
};


/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_STUDIO_SETUP_STATE_PROVE:
            return {...state,configInfo: initialState.configInfo, customizedWasmUint8array: initialState.customizedWasmUint8array}
        case types.CONFIG_CUSTOMIZED_WASM:
            return {...state, customizedWasmUint8array: { ...state.customizedWasmUint8array, uint8array: action.response}}
        case types.CONFIG_MD5:
            let SepoliaAddress = "";
            let GoerliAddress = "";
            action.response.deployment.forEach(item=>{
                if(item.chain_id === 11155111 && SepoliaAddress === ""){
                    SepoliaAddress = item.address;
                }else if(item.chain_id === 5 && GoerliAddress === ""){
                    GoerliAddress = item.address;
                }
            })
            return {...state, customizedWasmUint8array: { ...state.customizedWasmUint8array,
                    md5: action.response.md5,
                    setup: initialState.customizedWasmUint8array.setup,
                    prove: initialState.customizedWasmUint8array.prove,
                    deploy: {
                        11155111: {...initialState.customizedWasmUint8array.deploy[11155111], deployedAddress: SepoliaAddress},
                        5: {...initialState.customizedWasmUint8array.deploy[5], deployedAddress: GoerliAddress},
                    }
            }}
        case types.CONFIG_POC_INFO:
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array,prove:  action.response.defaultProveResult, execute:{
                        ...state.customizedWasmUint8array.execute, txHash: action.response.blockNumber, pub: action.response.defaultExecuteResult.pub ?? "", pvt: action.response.defaultExecuteResult.pvt ?? "", output: action.response.defaultExecuteResult.output ?? null
                    }}}
        case types.CONFIG_EXECUTE_STATE_RESULT_TITLES:
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, execute:{...state.customizedWasmUint8array.execute, stateResult:{...state.customizedWasmUint8array.execute.stateResult, titles: action.response}}}}
        case types.SET_EXECUTED_STATE_RESULT_VALUES:
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, execute:{...state.customizedWasmUint8array.execute, stateResult:{...state.customizedWasmUint8array.execute.stateResult, values: action.response}}}}
        case types.CONFIGURED_MD5.success():
            return {...state, customizedWasmUint8array: { ...state.customizedWasmUint8array, configuredMd5: action.response.result.data}}
        case types.NEW_WASM_IMAGE.success():
            return {...state, customizedWasmUint8array: { ...state.customizedWasmUint8array, md5: action.response.md5, setup: {...state.customizedWasmUint8array.setup, id: action.response.id}}}
        case types.CHECK_IMAGE_STATUS.success():
            console.log("setup: "+action.response.result.data)
            return {...state,
                customizedWasmUint8array: {
                ...state.customizedWasmUint8array,
                    setup: {
                    ...state.customizedWasmUint8array.setup,
                        status: action.response.result.data[0].status,
                        taskType: action.response.result.data[0].task_type,
                        submit_time: action.response.result.data[0].submit_time,
                        process_started: action.response.result.data[0].process_started,
                        process_finished: action.response.result.data[0].process_finished,
                        user_address: action.response.result.data[0].user_address,
                }
            }
            }
        case types.DEPLOY_WASM_IMAGE.success():
            console.log(action.payload)
            let payload = null;
            payload = typeof action.payload === "string" ? JSON.parse(action.payload) : action.payload;
            console.log(payload)
            console.log(action.response.id)
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, deploy: {...state.customizedWasmUint8array.deploy, [payload.chain_id]: { ...state.customizedWasmUint8array.deploy[payload.chain_id], id: action.response.id}}}}
        case types.DEPLOYED_IMAGE.success():
            action.response.result[0].deployment.forEach(deployment => {
                state = {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, deploy: {...state.customizedWasmUint8array.deploy, [deployment.chain_id]:{...state.customizedWasmUint8array.deploy[deployment.chain_id],  deployedAddress: deployment.address}}}}
            })
            return state;
        case types.CHECK_DEPLOY_WASM_IMAGE_STATUS.success():
            let Sepolia =  {}
            let Goerli = {}
            action.response.result.data.forEach(item => {
                console.log(item)
                if (item.chain_id === 11155111 && Object.keys(Sepolia).length === 0) {
                    Sepolia.status = item.status;
                    Sepolia.taskType = item.task_type;
                    Sepolia.submit_time = item.submit_time;
                    Sepolia.process_started = item.process_started;
                    Sepolia.process_finished = item.process_finished;
                    Sepolia.user_address = item.user_address;
                } else if (item.chain_id === 5 && Object.keys(Goerli).length === 0) {
                    Goerli.status = item.status;
                    Goerli.taskType = item.task_type;
                    Goerli.submit_time = item.submit_time;
                    Goerli.process_started = item.process_started;
                    Goerli.process_finished = item.process_finished;
                    Goerli.user_address = item.user_address;
                }
            })
            console.log(Sepolia)
            console.log(Goerli)
            return {...state, customizedWasmUint8array:
                    {...state.customizedWasmUint8array,
                        deploy:
                            {
                                11155111: Object.keys(Sepolia).length > 0 ? {...state.customizedWasmUint8array.deploy[11155111],  ...Sepolia} : {...state.customizedWasmUint8array.deploy[11155111]},
                                5: Object.keys(Goerli).length > 0 ? {...state.customizedWasmUint8array.deploy[5],  ...Goerli} : {...state.customizedWasmUint8array.deploy[5]}
                            }
                    }
            }
        case types.CONFIGURED_PROVE.success():
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, configuredProves: action.response.result.data}}
        case types.CONFIG_PROVE:
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, prove: {id: action.response.id, status: null,
                        taskType: null,
                        submit_time: null,
                        process_started: null,
                        process_finished: null,
                        user_address: null,
                        proof: [],
                        instances: [],
                        aux: [],
                        pub: [],
                        pvt: "", status_message: null}, verify: {result: "UNSENT"}}}
        case types.PROVE_WASM_IMAGE.success():
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, prove: {...initialState.customizedWasmUint8array.prove, id: action.response.id}}}
        case types.CHECK_PROVE_WASM_IMAGE_STATUS.success():
            const data = action.response.result.data.filter(item => item._id.$oid === state.customizedWasmUint8array.prove.id )
            return {...state, customizedWasmUint8array:
                    {...state.customizedWasmUint8array,
                        prove:
                            {...state.customizedWasmUint8array.prove,
                                status: data[0].status,
                                proof: data[0].proof,
                                pvt: data[0].private_inputs,
                                pub: data[0].public_inputs,
                                instances: data[0].batch_instances,
                                aux: data[0].aux,
                                taskType: data[0].task_type,
                                submit_time: data[0].submit_time,
                                process_started: data[0].process_started,
                                process_finished: data[0].process_finished,
                                user_address: data[0].user_address,
                                status_message: data[0].status_message ?? null
                            }
                    }
            }
        case types.UPDATE_SOURCE_CONTRACT_ADDRESS:
            return {...state, configInfo: {...state.configInfo, sourceContractAddress: action.payload}}
        case types.UPDATE_SOURCE_EVENT_ABI:
            return {...state, configInfo: {...state.configInfo, sourceEventABI: action.payload}}
        case types.UPDATE_TX_HASH:
            return {...state,  customizedWasmUint8array: {...state.customizedWasmUint8array, execute: {...state.customizedWasmUint8array.execute, txHash: action.payload}}}
        case types.EXECUTE_WASM_MAPPING.request():
            return {...state,  customizedWasmUint8array: {...state.customizedWasmUint8array,prove: {...initialState.customizedWasmUint8array.prove},
                    execute: {...state.customizedWasmUint8array.execute,
                        output:null,
                        pub: "",
                        pvt: "",
                        error: null,
                    }}}
        case types.EXECUTE_WASM_MAPPING.failure():
            return {...state,  customizedWasmUint8array: {...state.customizedWasmUint8array,prove: {...initialState.customizedWasmUint8array.prove},
                    execute: {...state.customizedWasmUint8array.execute,
                        output: null,
                        pub: "",
                        pvt:"",
                        error: action.response.error,
                    }}}
        case types.EXECUTE_WASM_MAPPING.success():
            return {...state,  customizedWasmUint8array: {...state.customizedWasmUint8array,
                    execute: {...state.customizedWasmUint8array.execute,
                        output:action.response.state,
                        pub: action.response.publicInputStr,
                        pvt: action.response.privateInputStr,
                        error: null,
            }}}
        case types.VERIFY_CONTRACT.request():
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, verify: {result: null}}}
        case types.VERIFY_CONTRACT.success():
            return {...state, customizedWasmUint8array: {...state.customizedWasmUint8array, verify: {result: action.response}}}
        default:
            return state;
    }
}

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getCustomizedWasm = (state) =>{
    return state.entities.poc.customizedWasmUint8array;
}

export const getPOCConfigInfo = (state) =>{
    return state.entities.poc.configInfo;
}
