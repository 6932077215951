import React, { useEffect } from "react";
import ParticleImage, {
  forces,
  ParticleForce,
  ParticleOptions,
  Vector,
} from "react-particle-image";
import circle from "../../../assets/ora/circle.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import useScreen from "../../../hooks/aboutScreen/useScreen";

const particleOptions: ParticleOptions = {
  filter: ({ x, y, image }) => {
    // Get pixel
    const pixel = image.get(x, y);
    // Make a particle for this pixel if blue > 50 (range 0-255)
    return pixel.b > 50;
  },
  color: ({ x, y, image }) => "#61dafb",
  mass: () => 50,
  friction: () => 0.25,
  initialPosition: ({ canvasDimensions }) => {
    return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
  },
};

const motionForce = (x: number, y: number): ParticleForce => {
  return forces.disturbance(x, y, 100);
};

const Circle = ({
  width = Number(innerWidth),
  height = Number(innerHeight),
  entranceAnimation = true,
  color = "#61dafb",
}) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const { SCREEN_SIZE } = useScreen();
  const [entropy, setEntropy] = React.useState(entranceAnimation ? 1500 : 20);

  let showParticleTimeout: NodeJS.Timeout;

  useEffect(() => {
    if (entranceAnimation)
      showParticleTimeout = setTimeout(() => {
        // setEntropy(1500);
        setEntropy(20);
      }, 1500);

    return () => {
      if (entranceAnimation) clearTimeout(showParticleTimeout);
    };
  }, []);

  return (
    <ParticleImage
      src={circle}
      width={width}
      height={height}
      scale={matchesSM ? 0.5 : 1}
      entropy={entropy}
      maxParticles={SCREEN_SIZE.SMALL ? 1500 : 4000}
      particleOptions={{
        ...particleOptions,
        color: () => color,
        radius: () =>
          SCREEN_SIZE.SMALL ? Math.random() * 1.5 + 1 : Math.random() * 1.5 + 2,
      }}
      // mouseMoveForce={motionForce}
      // touchMoveForce={motionForce}
      backgroundColor="transprant"
    />
  );
};

export default Circle;
