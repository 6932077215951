import styled from "styled-components";
import {FaTelegramPlane} from "react-icons/fa";

type TelegramIconProps = {
    height: string,
    width: string,
    fill: string,
    hover?: string
}


const TelegramIcon = styled(FaTelegramPlane)`

    height: ${(props: TelegramIconProps) => props.height};
    width: ${(props: TelegramIconProps) => props.width};
        
   fill: ${(props: TelegramIconProps) => props.fill};
   
   &:hover{
    fill: ${(props: TelegramIconProps) => props.hover??props.fill};
    }
    
`

export default TelegramIcon;
