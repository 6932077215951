export function fromHexString(hexString) {
    hexString = hexString.startsWith("0x") ? hexString.slice(2) : hexString;
    hexString = hexString.length % 2 ? "0" + hexString : hexString;
    return Uint8Array.from(Buffer.from(hexString, "hex"));
}

export function toHexString(bytes) {
    return Buffer.from(bytes).toString("hex");
}

export function areEqualArrays(first, second) {
    return (
        first.length === second.length &&
        first.every((value, index) => value === second[index])
    );
}

export function trimPrefix(str, prefix) {
    if (str.startsWith(prefix)) {
        str = str.substring(prefix.length);
    }
    return str;
}

export function logDivider() {
    const line = "=".repeat(process.stdout.columns);
    console.log(line);
}