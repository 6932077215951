/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import {types} from "../wallet";
import {types as pocTypes} from "../../modules/poc";
import Web3 from "web3";
import BN from "bn.js";

const initialState = {
    address: null,
    chainId: 11155111,
    ethBalance: 0
}

export const schema = {
    name: "wallet",
    id: "id",
};


/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CONFIGURE_WALLET:
            return {...state, address: action.payload.address}
        case types.WALLET_CONNECT:
            return {...state, address: action.payload.address}
        case types.WALLET_DISCONNECT:
            return initialState;
        case types.USER_ETH_BALANCE.success():
            return {...state, ethBalance: parseFloat(action.response.ethBalance)}
        case types.SEND_TX.success():
            return {...state, ethBalance: state.ethBalance + action.payload.amount}
        case types.SEND_TXH.success():
            const txh_amount =  parseFloat(convertAmount(action.response.result.value));
            return {...state, ethBalance: state.ethBalance + txh_amount}
        case pocTypes.NEW_WASM_IMAGE.request():
            return {...state, ethBalance: state.ethBalance - 0.001}
        case pocTypes.PROVE_WASM_IMAGE.request():
            return {...state, ethBalance: state.ethBalance - 0.001}
        case pocTypes.DEPLOY_WASM_IMAGE.request():
            return {...state, ethBalance: state.ethBalance - 0.001}
        default:
            return state;
    }
}

export function convertAmount(balance){
    const web3 = new Web3();
    let amt = new BN(balance, 10, "le").toString();
    return web3.utils.fromWei(amt, "ether");
}

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getWallet = (state) => {
    return state.entities.wallet
}

