import {actionTypesConstructor} from "../utils";
import {combineReducers} from "redux";
import graphAbi from "../../utils/abi/graphAbi";

const ethers = require('ethers');

let provider = new ethers.providers.WebSocketProvider("wss://sepolia.infura.io/ws/v3/66bdab690554486092111f634a9e891c");

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/

export const types = {
    AUTOMATION_SUBSCRIBER: actionTypesConstructor(
        "AUTOMATION_SUBSCRIBER_REQUEST",
        "AUTOMATION_SUBSCRIBER_SUCCESS",
        "AUTOMATION_SUBSCRIBER_FAILURE"
    ),
    AUTOMATION_SUBSCRIBER_CANCELLED: "AUTOMATION_SUBSCRIBER_CANCELLED"
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
    isSubscriptionOn: false
}

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

let contractInstance = null;
const getContractInstance = (getState) => {
    if (!contractInstance) {
        const activeZkgCreatorAddress = getState().zkGraphs.activeZkg.creatorAddress;
        const activeZkgGraphAddress = getState().zkGraphs.activeZkg.graphAddress;
        const activeZkgGraphYaml = getState().entities.zkGraphs[activeZkgCreatorAddress][activeZkgGraphAddress].graphYaml;
        // TODO: get wss provider from ankr
        // const jsonRpcProvider = testNets.find((net)=>net.label.toLowerCase() === activeZkgGraphYaml.dataSources[0].network.toLowerCase()).jsonRpcProvider;
        // return new ethers.Contract(activeZkgGraphAddress, abi, new ethers.providers.WebSocketProvider(jsonRpcProvider));
        contractInstance = new ethers.Contract(activeZkgGraphAddress, graphAbi, provider);
    }

    return contractInstance;
}

export const actions = {
    subscribeAutomation: ()=>{
        return async (dispatch, getState) => {

            const contract = getContractInstance(getState);
            //
            // const event = {
            //     blockNumber: 5105234,
            //     blockHash: '0xfe976e9b6e6d737d17891b87f49cc84897b9d4407089c1164b51dc510bb58acd',
            //     transactionIndex: 0,
            //     removed: false,
            //     address: '0xCC600f8C3212a8eEe8297344F55C6fc53740C023',
            //     data: '0x000000000000000000000000518ea7a54f40ad77efde76d23b3cdcb28ba1a56000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000020c040622600000000000000000000000000000000000000000000000000000000',
            //     topics: [
            //         '0x8fb903586ebc56516de412c7b56c608ea7120f5e22976c44b5b3130c4dc071a6'
            //     ],
            //     transactionHash: '0xd8c75bc58fdb5f113f8e135a5ff800461dfbc95b664d7479cd2f6ae0a28f8042',
            // }
            // return await dispatch(
            //     {
            //         type: types.AUTOMATION_SUBSCRIBER.success(),
            //         payload: {
            //             node: "0x518eA7A54f40AD77EFDE76d23B3cdcb28Ba1A560",
            //             payload: "0xc040622600000000000000000000000000000000000000000000000000000000",
            //             srcBlockNum: event.blockNumber,
            //             blockhash: event.transactionHash,
            //             event
            //         }
            //     }
            // );

            return contract.on("Trigger", async (node, payload, event) => {
                return await dispatch(
                    {
                        type: types.AUTOMATION_SUBSCRIBER.success(),
                        payload: {
                            node,
                            payload,
                            srcBlockNum: event.blockNumber,
                            blockhash: event.transactionHash,
                            event
                        }
                    }
                )
            });
        }
    },
    cancelAutomationSubscription: () => {
        return async (dispatch, getState) => {
            const contract = getContractInstance(getState);
            contract.removeAllListeners("Trigger");
            contractInstance=null;
            return await dispatch(
                {
                    type: types.AUTOMATION_SUBSCRIBER_CANCELLED,
                }
            )
        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const data = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTOMATION_SUBSCRIBER.success():
            return {...state, isSubscriptionOn: true};
        case types.AUTOMATION_SUBSCRIBER_CANCELLED:
            return {...state, isSubscriptionOn: false};
        default:
            return state;
    }
}

const reducer = combineReducers({data})
export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getIsSubscriptionOn = (state) => {
    return state.automation.data.isSubscriptionOn;
}
