import {EndPoint} from "./types/requestTypes";

export const baseUrl = () => {
    let env = process.env.REACT_APP_ENV;
    if (env === "production") {
        return process.env.REACT_APP_PROD_BASE;
    } else if (env === "development") {
        return process.env.REACT_APP_DEV_BASE;
    }
};

const opmlApiUrl = 'https://aibackend.hyperoracle.io'

export default {
    sendToCompile: ():EndPoint => ({
        url: "https://compiler.hyperoracle.io/compile",
        isProtected: false
    }),
    getEthStatus: (zkgid:string):EndPoint => ({
        url:`https://node.hyperoracle.io/graph?zkgid=${zkgid}`,
        isProtected: false,
    }),
    postNewWasmImage: ():EndPoint => ({
        url: `${baseUrl()}/setup`,
        isProtected: false,
    }),
    fetchConfiguredMD5: ():EndPoint => ({
        url: `${baseUrl()}/tasks?tasktype=Setup`,
        isProtected: false,
    }),
    checkWasmImageStatus:  (md5:string, taskType: string | null =null):EndPoint => ({
        url: `${baseUrl()}/tasks?md5=${md5}${taskType? "&tasktype="+taskType : ""}`,
        isProtected: false
    }),
    deployWasmImageURL: ():EndPoint => ({
        url: `${baseUrl()}/deploy`,
        isProtected: false,
        contentType: { "Content-Type":"application/json" },
    }),
    proveWasmImageURL: ():EndPoint => ({
        url: `${baseUrl()}/prove`,
        isProtected: false,
        contentType: { "Content-Type":"application/json" },
    }),
    searchImageURL: (md5:string,):EndPoint => ({
        url: `${baseUrl()}/image?md5=${md5}`,
        isProtected: false,
    }),
    getUserBalance: (address:string):EndPoint => ({
        url: `${baseUrl()}/user?user_address=${address}`,
        isProtected: false,
    }),
    sendTXHash: (address: string):EndPoint => ({
        url: `${baseUrl()}/pay`,
        isProtected: false,
        contentType: { "Content-Type":"application/json" },
    }),
    uploadZkgraph2Pinata: ():EndPoint => ({
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        isProtected: true,
    }),
    uploadWasm2Td: ():EndPoint => ({
        url: "https://zkwasm.hyperoracle.io/td/upload",
        isProtected: false,
    }),
    initOpml: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/opMLRequest`,
        isProtected: false
    }),
    generateText2Image: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/txt2img`,
        isProtected: false,
        isText: true,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    setIsCorrect: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/setIsCorrect`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    submitterUploadResult: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/submitterUploadResult`,
        isProtected: false,
        isText: true
    }),
    startChallenge: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/startChallenge`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    challengerRespond: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/challengerRespond`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    submitterRespond: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/submitterRespond`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    challengerAssert: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/challengerAssert`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    postOpml: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/post`,
        isProtected: false
    }),
    generateText2LLM: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/llama`,
        isProtected: false,
        isText: true,
        contentType: {
            'Content-Type' : 'application/json'
        }
    }),
    getResultHashAndSlot: ():EndPoint => ({
        url: `${opmlApiUrl}/api/v1/dalle/getResultHashAndSlot`,
        isProtected: false,
        contentType: {
            'Content-Type' : 'application/json'
        }
    })
};
