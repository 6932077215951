export default function doElementsIntersect(element1, element2) {
  const rect1 = element1?.getBoundingClientRect();
  const rect2 = element2?.getBoundingClientRect();

  if (rect1 == null) return false;
  if (rect2 == null) return false;

  return !(
    rect1?.right < rect2?.left ||
    rect1?.left > rect2?.right ||
    rect1?.bottom < rect2?.top ||
    rect1?.top > rect2?.bottom
  );
}
