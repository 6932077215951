import {FETCH_DATA} from "./api";

export const WEB3 = 'WEB3';

export const web3API = () => (next) => (action) => {
    const callAPI = action[WEB3];
    if (typeof callAPI === "undefined") {
        return next(action);
    }

    const {func, types, payload } = callAPI;

    console.log(func, types );

    const actionWith = (data) => {
        const finalAction = { ...action, ...data };
        delete finalAction[FETCH_DATA];
        return finalAction;
    };

    const [requestType, successType, failureType] = types;

    next(actionWith({ type: requestType }));

    return run(func).then(
        (response) =>
            next(
                actionWith({
                    type: successType,
                    payload: payload,
                    response
                })
            ),
        (error) =>
            next(
                actionWith({
                    type: failureType,
                    message: error.message || "获取数据失败",
                    error: error.error || "获取数据失败",
                })
            )
    );
}

const run = (func) =>{
    if (typeof window.ethereum !== 'undefined') {
        try {
            return func.then(res => {
                console.log(res);
                return res;
            }).catch((error) => Promise.reject(error));
        } catch (error) {
            console.error('Error making payment:', error);
           return  Promise.reject(error)
        }
    }else {
        return Promise.reject({error: 'Ethereum provider not detected. Please install MetaMask or another compatible wallet.'})
    }
}