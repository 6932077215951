import {createContext, ReactNode, useState} from "react";
import * as React from "react";

interface WalletConnectContextProps {
    open: boolean;
    recipientAddress: string;
    setOpen: (open: boolean) => void;
    setRecipientAddress: (senderAddress: string) => void;
}

const WalletConnectContext = createContext<WalletConnectContextProps>({
    open: false,
    recipientAddress: "",
    setOpen: () => {},
    setRecipientAddress: () => {},
})


type WalletConnectContextProviderProps = {
    children: ReactNode | undefined
}
export const WalletConnectProvider = (props: WalletConnectContextProviderProps) => {
    const { children } = props;
    const [open, setOpen] = useState(false);
    const [recipientAddress, setRecipientAddress] = useState("");

    const contextValue: WalletConnectContextProps = { open, setOpen, recipientAddress, setRecipientAddress };

    return (
        // @ts-ignore
        <WalletConnectContext.Provider value={contextValue}>
            {children}
        </WalletConnectContext.Provider>
    );
}

export default WalletConnectContext;
