import React, { useEffect, useState, useRef, MutableRefObject } from "react";
import { Grid, Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import homeSloganBg from "../../assets/ora/circle diagram.png";
import bigCircle from "../../assets/home/circleBg.png";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";
import StepsIntro, { homeSteps } from "../../components/Ora/StepsIntro";
import StepOne from "../../assets/ora/steps/stepOne.png";
import StepTwo from "../../assets/ora/steps/StepTwo.png";
import ParticleComponent from "../../components/PracticleEffect";
import Circle from "../../components/PracticleEffect/Circle";
import { TypeAnimation } from "react-type-animation";
import "./style.css";
import INFERENCE from "../../assets/home/inference.png";
import ANY from "../../assets/home/any.png";
import Divider from "../../components/PageDivider";
import ONCHAIN from "../../assets/home/onchain_ai_engine.svg";
import FAST from "../../assets/home/fast_development.svg";
import { motion } from "framer-motion";
import { transform } from "lodash";
import DEV_DOC from "../../assets/home/dev_doc.png";
import CURVE_TOP from "../../assets/innercurveTop.png";
import CURVE_TOP_HALF from "../../assets/innercurveTop_half.png";
import CURVE_RIGHT from "../../assets/innercurveRight.png";
import CURVE_LEFT from "../../assets/innercurveLeft.png";
import RunningText from "../../components/RunningText";
import doElementsIntersect from "../../utils/doElementsIntersect";

const Home: React.FC = () => {
  const theme = useTheme();
  const circleRef: MutableRefObject<HTMLImageElement | null> = useRef(null);
  const particleRef: MutableRefObject<HTMLImageElement | null> = useRef(null);

  const [isIntersect, setIsIntersect] = useState(false);
  const [particleTransform, setParticleTransform] = useState(false);
  const [hideOra, setHideOra] = useState(false);
  const enableScroll = useRef(false);
  const [footerIntersect, setFooterIntersect] = useState(false);

  //Disable scroll when mount
  useEffect(() => {
    document.querySelector<HTMLInputElement>("body")!.style.overflow = "hidden";

    setTimeout(() => {
      enableScroll.current = true;
    }, 3000);

    return () => {
      document.querySelector<HTMLInputElement>("body")!.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    let footerEle = document.querySelector("#footerSection");

    const OnScroll = () => {
      if (particleRef)
        setIsIntersect(
          doElementsIntersect(circleRef.current, particleRef.current)
        );
      if (enableScroll.current) setParticleTransform(true);

      setFooterIntersect(doElementsIntersect(footerEle, particleRef.current));
    };
    addEventListener("wheel", OnScroll);
    addEventListener("touchmove", OnScroll);

    return () => {
      removeEventListener("wheel", OnScroll);
      removeEventListener("touchmove", OnScroll);
    };
  }, []);

  useEffect(() => {
    dispatchEvent(
      new CustomEvent("CircleTouchFooter", {
        detail: { status: footerIntersect },
      })
    );
  }, [footerIntersect]);

  useEffect(() => {
    let hideOraTimeout: NodeJS.Timeout;
    if (particleTransform == true)
      hideOraTimeout = setTimeout(() => {
        setHideOra(true);
        document.querySelector<HTMLInputElement>("body")!.style.overflow =
          "visible";
        dispatchEvent(new Event("PageScrolled"));
      }, 2000);

    return () => clearTimeout(hideOraTimeout);
  }, [particleTransform]);

  return (
    <Grid item container xs={12} className={"HomeContainer"}>
      {/*home slogan*/}
      <div
        className="first_section"
        style={{
          transition: "height 1s",
          height: hideOra ? "0" : "100vh",
          width: "100vw",
          pointerEvents: "none",
          position: "relative",
        }}
      >
        <img
          style={{ position: "absolute", left: "17rem", top: 0, width: "5rem" }}
          src={CURVE_TOP}
          alt=""
        />
        <img
          style={{
            position: "absolute",
            right: "0",
            top: "55vh",
            width: "2.5rem",
          }}
          src={CURVE_RIGHT}
          alt=""
        />
      </div>

      <div
        className={`fixed_circle ${isIntersect ? "greyscale_circle" : ""} ${
          footerIntersect ? "footer_circle" : ""
        }`}
        style={{
          transition: hideOra ? "0.5s" : "4s",
          opacity: particleTransform ? 1 : 0,
        }}
        ref={particleRef}
      >
        {particleTransform && <Circle />}
      </div>

      {!hideOra && (
        <div
          className={`fixed_circle `}
          style={{
            transition: "0.5s",
            transitionDelay: "1.5s",
            opacity: particleTransform ? "0" : "1",
          }}
        >
          <ParticleComponent
            transform={particleTransform}
            setEnableScroll={() => (enableScroll.current = true)}
          />
        </div>
      )}

      <Grid
        item
        xs={12}
        container
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        direction={"row"}
        style={{ height: "90vh", position: "relative" }}
      >
        <Grid
          item
          container
          direction={"column"}
          style={{ position: "absolute" }}
        >
          {/* <TypeAnimation
                            sequence={['Call inference from your smart contract', 1500, 'AI Oracle Protocol', 500, 'ZK Oracle Protocol', 1000,]}
                            style={{ fontSize: '2.8rem', textAlign:"center", lineHeight: "4.68rem", textTransform: "uppercase", fontFamily: "Syncopate-Bold", color: "white",}}
                            repeat={Infinity}
                        />                       */}
          {hideOra && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                pointerEvents: "none",
              }}
            >
              <RunningText
                text={"Call inference from your smart contract"}
                style={{
                  width: "50rem",
                  fontSize: "2.8rem",
                  textAlign: "center",
                  lineHeight: "100%",
                  textTransform: "uppercase",
                  fontFamily: "Syncopate-Bold",
                  color: "white",
                  marginBottom: "2rem",
                }}
              />
              <RunningText
                style={{
                  fontFamily: "Syncopate-Bold",
                  color: "white",
                  fontSize: "1.8rem",
                  pointerEvents: "none",
                }}
                text="VERIFIABLE ORACLE PROTOCOL"
              />
            </motion.div>
          )}
        </Grid>
      </Grid>

      {/* Introducing AI Oracle*/}
      <section className="ai_oracle">
        <img
          src={CURVE_LEFT}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "2rem",
            transform: "translateY(-50%)",
          }}
          alt="shape"
        />
        <img
          src={CURVE_RIGHT}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "2rem",
            transform: "translateY(50%)",
          }}
          alt="shape"
        />
        <div className="ai_content">
          <div className="title">
            <span className="tag blue_bg">A VERIFIABLE ORACLE PROTOCOL</span>
            <h2>
              INTRODUCING
              <br /> AI ORACLE
            </h2>
            <div className="arrow_link">
              <Link href="#" color="inherit">
                <RunningText text={"TRY IT FIRST HAND"} />
              </Link>
              <GoArrowUpRight color={"#00f6ff"} />
            </div>
            <div className="oracle_illustration">
              <img src={INFERENCE} alt="Inference" />
              <img src={ANY} alt="Any" />
            </div>
          </div>
        </div>
      </section>

      {/* big circle */}
      <section className="bigcircle" style={{ opacity: 0 }}>
        <img ref={circleRef} alt={"big circle"} src={bigCircle} />
      </section>

      <section className="why_ora">
        <div className="why_frame">
          <h2 className="blue_bg">WHY ORA?</h2>

          <div className="why_item">
            <img src={ONCHAIN} alt="onchain ai engine" />
            <div className="text">
              <strong>ONCHAIN AI ENGINE</strong>

              <div className="list">
                <u>Currently support:</u>
                <span>&lt;&gt; Stable Diffusion</span>
                <span>&lt;&gt; LlaMA 2</span>
                <br />
                <u>Future support:</u>
                <span>&lt;&gt; Any Model</span>
              </div>
              <div className="arrow_link">
                <Link href="#" color="inherit">
                  <RunningText text={"BRING YOUR MODEL"} />
                </Link>
                <GoArrowUpRight color={"#00f6ff"} />
              </div>
            </div>
          </div>
          <div className="why_item">
            <img src={FAST} alt="Fast development" />
            <div className="text">
              <strong>FAST DEVELOPMENT</strong>

              <div className="list">
                <u>Coming to networks near you:</u>
                <span>&lt;&gt; Any Blockchain</span>
                <span>&lt;&gt; Any Layer2</span>
                <br />
                <u>Build your own:</u>
                <span>&lt;&gt; Programmable</span>
                <span>&lt;&gt; Permissionless</span>
                <span>&lt;&gt; Censorship-resistant</span>
              </div>
              <div className="arrow_link">
                <Link href="#" color="inherit">
                  <RunningText text={"BRING YOUR CLE"} />
                </Link>
                <GoArrowUpRight color={"#00f6ff"} />
              </div>
            </div>
          </div>
        </div>

        <div className="cards">
          <Link
            href="https://docs.hyperoracle.io"
            target="_blank"
            className="card blue_bg"
          >
            <span>DEVELOPER</span>
            <strong>DOCUMENTATION</strong>
            <div className="icon">
              <GoArrowUpRight color={"white"} />
            </div>
          </Link>
          <Link
            href="https://mirror.xyz/hyperoracleblog.eth/qbefsToFgFxBZBocwlkX-HXbpeUzZiv2UB5CmxcaFTM"
            target="_blank"
            className="card blue_bg"
          >
            <span>READ OUR</span>
            <strong>WHITE PAPER</strong>
            <div className="icon">
              <GoArrowUpRight color={"white"} />
            </div>
          </Link>
          <Link
            component={RouterLink}
            to={{ pathname: "/app/opml/sd" }}
            target="_blank"
            className="card blue_bg"
          >
            <span>EXPLORE</span>
            <strong>DEMOS</strong>
            <div className="icon">
              <GoArrowUpRight color={"white"} />
            </div>
          </Link>
        </div>
      </section>
    </Grid>
  );
};

export default Home;
