import { Action, combineReducers } from "redux";
import { BigNumber, ethers } from 'ethers'
import { actionTypesConstructor, dispatchAction } from "../utils";
import { POST_DATA } from "../middlewares/api";
import url from "../../utils/url";
import { OPML_CONTRACT_MODE, OpmlEntityState, types as opmlEntityTypes } from "./entities/opml";
import { getRandomLLMPrompt, getRandomPrompt } from "../../utils/opml";
import { post } from "../../utils/requests";
import opmlPromptsAbi from '../../utils/abi/opmlPromptsAbi'
import { Indexed } from "ethers/lib/utils.js";
import { changeNetwork, getNetwork } from "../utils/wallet_helper";
import { types as walletTypes } from "./wallet";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/




export const types = {
    GENERATE_OPML_IMAGE: actionTypesConstructor(
        "APP/OPML/GENERATE_OPML_IMAGE/REQUEST",
        "APP/OPML/GENERATE_OPML_IMAGE/SUCCESS",
        "APP/OPML/GENERATE_OPML_IMAGE/FAILURE",
    ),
    SUBMIT_GENERATED_OPML_IMAGE: actionTypesConstructor(
        "APP/OPML/SUBMIT_GENERATED_OPML_IMAGE/REQUEST",
        "APP/OPML/SUBMIT_GENERATED_OPML_IMAGE/SUCCESS",
        "APP/OPML/SUBMIT_GENERATED_OPML_IMAGE/FAILURE",
    ),
    REQUEST_OPML: actionTypesConstructor(
        "APP/OPML/REQUEST_OPML/REQUEST",
        "APP/OPML/REQUEST_OPML/SUCCESS",
        "APP/OPML/REQUEST_OPML/FAILURE",
    ),
    GENERATE_CHALLENGER_ID: actionTypesConstructor(
        "APP/OPML/GENERATE_CHALLENGER_ID/REQUEST",
        "APP/OPML/GENERATE_CHALLENGER_ID/SUCCESS",
        "APP/OPML/GENERATE_CHALLENGER_ID/FAILURE",
    ),
    CHALLENGER_RESPOND: actionTypesConstructor(
        "APP/OPML/CHALLENGER_RESPOND/REQUEST",
        "APP/OPML/CHALLENGER_RESPOND/SUCCESS",
        "APP/OPML/CHALLENGER_RESPOND/FAILURE",
    ),
    SUBMITTER_RESPOND: actionTypesConstructor(
        "APP/OPML/SUBMITTER_RESPOND/REQUEST",
        "APP/OPML/SUBMITTER_RESPOND/SUCCESS",
        "APP/OPML/SUBMITTER_RESPOND/FAILURE",
    ),
    OPML_ASSERT: actionTypesConstructor(
        "APP/OPML/ASSERT/REQUEST",
        "APP/OPML/ASSERT/SUCCESS",
        "APP/OPML/ASSERT/FAILURE",
    ),
    LLM_OUTPUT: actionTypesConstructor(
        "APP/OPML/LLM_OUTPUT/REQUEST",
        "APP/OPML/LLM_OUTPUT/SUCCESS",
        "APP/OPML/LLM_OUTPUT/FAILURE",
    ),
    POST: actionTypesConstructor(
        "APP/OPML/GET/POST/REQUEST",
        "APP/OPML/GET/POST/SUCCESS",
        "APP/OPML/GET/POST/FAILURE",
    ),
    CONNECT_CONTRACT: actionTypesConstructor(
        "APP/OPML/CONNECT_CONTRACT/REQUEST",
        "APP/OPML/CONNECT_CONTRACT/SUCCESS",
        "APP/OPML/CONNECT_CONTRACT/FAILURE",
    ),
}

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
    isRequestingOpml: false, // axios.post("/api/v1/dalle/opMLRequest", data, {timeout:300000})
    isLoadingPost: false, // const response = await fetch(`${serverURL}` + "/api/v1/post", {
    isGeneratingImg: false, //        axios.post("/api/v1/dalle/txt2img", {contractAddress: contractAddress,  prompt: randomPrompt }, {timeout:300000})
    isSubmittingGeneratedImg: false, // const response = await fetch(`${serverURL}` + "/api/v1/post",
    isGeneratingChallengeId: false, //axios.post("/api/v1/dalle/startChallenge", data, {timeout:300000})
    isChallengerResponding: false, // axios.post("/api/v1/dalle/challengerRespond", data, {timeout:300000})
    isSubmitterResponding: false, //axios.post("/api/v1/dalle/submitterRespond", data, {timeout:300000})
    isAsserting: false, // axios.post("/api/v1/dalle/challengerAssert", data, {timeout:300000})
    isFetchingLLMOutput: false, // axios.post("/api/v1/dalle/llama", {contractAddress: contractAddress,  prompt: randomPrompt }, {timeout:300000})
    isConnectContract: false
}

export type OpmlState = typeof initialState

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
    initEthers: () => {
        return async (dispatch: any, getState: any) => {
            const state = (getState().entities.opml as OpmlEntityState)
            await dispatch({
                type: types.CONNECT_CONTRACT.request()
            })
            try {
                const provider = new ethers.providers.Web3Provider((window as any).ethereum);
                const contract = new ethers.Contract(state.contractAddress.opml, opmlPromptsAbi, provider.getSigner())
                // listen contract
                contract.on('promptsUpdated', async (modeId: BigNumber, input: string, output: string) => {
                    const state = (getState().entities.opml as OpmlEntityState)
                    const mode = modeId.toNumber()
                    if (state.contractMode === mode && state.form.prompt === input) {
                        await dispatch({
                            type: opmlEntityTypes.OPML_IPFS_HASH_UPDATE,
                            payload: output
                        })
                    }
                });
                await dispatch({
                    type: opmlEntityTypes.OPML_CONTRACT_UPDATE,
                    payload: contract
                })
                await dispatch({
                    type: types.CONNECT_CONTRACT.success()
                })
            } catch (error) {
                await dispatch({
                    type: types.CONNECT_CONTRACT.failure()
                })
            }

        }
    },
    // TODO - add action creators
    initOpml: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.initOpml()
            const formData = (getState().entities.opml as OpmlEntityState).form
            const data = JSON.stringify({
                modelName: formData.modelName,
                prompt: formData.prompt
            })

            const res = await dispatch(dispatchAction(
                POST_DATA,
                types.REQUEST_OPML.all(),
                endpoint,
                null,
                data
            ))
            const { response } = res
            await dispatch({
                type: opmlEntityTypes.OPML_CONFIG_CONTRACT_ADDRESS_UPDATE,
                payload: response.MPChallenge
            })
        }
    },
    generateOpmlText2Image: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.generateText2Image()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            await dispatch({
                type: types.GENERATE_OPML_IMAGE.request()
            })
            await dispatch({
                type: opmlEntityTypes.OPML_CONTRACT_MODE_UPDATE,
                payload: OPML_CONTRACT_MODE.SD
            })
            const contract = opmlEntity.contract as unknown as (ethers.Contract | undefined)
            try {
                const ret = await contract?.calculateAIResult(opmlEntity.contractMode, opmlEntity.form.prompt)
                await ret.wait()
                const data = JSON.stringify({
                    contractAddress: opmlEntity.config.contractAddress,
                    prompt: opmlEntity.form.prompt
                })
                const res = await post(endpoint, data)
                const imageUrl = "data:image/png;base64," + res
                await dispatch({
                    type: opmlEntityTypes.OPML_FORM_UPDATE,
                    payload: {
                        photo: imageUrl
                    }
                })
                await post(url.setIsCorrect(), JSON.stringify({
                    contractAddress: opmlEntity.config.contractAddress,
                    isCorrect: true
                }))
                await post(url.submitterUploadResult(), JSON.stringify({
                    contractAddress: opmlEntity.config.contractAddress,
                }))
                const hashRes = await post(url.getResultHashAndSlot(), JSON.stringify({
                    contractAddress: opmlEntity.config.contractAddress,
                }))
                await dispatch({
                    type: opmlEntityTypes.OPML_HASH_UPDATE,
                    payload: {
                        value: hashRes.resultHash,
                        slot: hashRes.slot
                    }
                })
                await dispatch({
                    type: types.GENERATE_OPML_IMAGE.success()
                })
            } catch (error) {
                await dispatch({
                    type: types.GENERATE_OPML_IMAGE.failure()
                })
            }

        }
    },
    generateIncorrectImage: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.generateText2Image()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const randomPrompt = getRandomPrompt(opmlEntity.form.prompt);

            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                prompt: randomPrompt
            })
            await dispatch({
                type: types.GENERATE_OPML_IMAGE.request()
            })
            const res = await post(endpoint, data)
            const imageUrl = "data:image/png;base64," + res
            await dispatch({
                type: opmlEntityTypes.OPML_FORM_UPDATE,
                payload: {
                    photo: imageUrl
                }
            })
            await post(url.setIsCorrect(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                isCorrect: false
            }))
            await post(url.submitterUploadResult(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            const hashRes = await post(url.getResultHashAndSlot(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            await dispatch({
                type: opmlEntityTypes.OPML_HASH_UPDATE,
                payload: {
                    value: hashRes.resultHash,
                    slot: hashRes.slot
                }
            })
            await dispatch({
                type: types.GENERATE_OPML_IMAGE.success()
            })
        }
    },
    generateOpmlText2LLM: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.generateText2LLM()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            await dispatch({
                type: types.LLM_OUTPUT.request()
            })
            await dispatch({
                type: opmlEntityTypes.OPML_CONTRACT_MODE_UPDATE,
                payload: OPML_CONTRACT_MODE.LLM
            })
            const contract = opmlEntity.contract as unknown as (ethers.Contract | undefined)
            const ret = await contract?.calculateAIResult(opmlEntity.contractMode, opmlEntity.form.prompt)
            await ret.wait()
            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                prompt: opmlEntity.form.prompt
            })
            const res = await post(endpoint, data)
            await dispatch({
                type: opmlEntityTypes.OPML_LLM_UPDATE,
                payload: {
                    output: res
                }
            })
            await post(url.setIsCorrect(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                isCorrect: true
            }))
            await post(url.submitterUploadResult(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            const hashRes = await post(url.getResultHashAndSlot(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            await dispatch({
                type: opmlEntityTypes.OPML_HASH_UPDATE,
                payload: {
                    value: hashRes.resultHash,
                    slot: hashRes.slot
                }
            })
            await dispatch({
                type: types.LLM_OUTPUT.success()
            })
        }
    },
    generateIncorrectLLM: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.generateText2LLM()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const randomPrompt = getRandomLLMPrompt(opmlEntity.form.prompt);

            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                prompt: randomPrompt
            })
            await dispatch({
                type: types.LLM_OUTPUT.request()
            })
            const res = await post(endpoint, data)
            await dispatch({
                type: opmlEntityTypes.OPML_LLM_UPDATE,
                payload: {
                    output: res
                }
            })
            await post(url.setIsCorrect(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
                isCorrect: false
            }))
            await post(url.submitterUploadResult(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            const hashRes = await post(url.getResultHashAndSlot(), JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress,
            }))
            await dispatch({
                type: opmlEntityTypes.OPML_HASH_UPDATE,
                payload: {
                    value: hashRes.resultHash,
                    slot: hashRes.slot
                }
            })
            await dispatch({
                type: types.LLM_OUTPUT.success()
            })
        }
    },
    startChallenge: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.startChallenge()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress
            })
            await dispatch({
                type: types.GENERATE_CHALLENGER_ID.request()
            })
            try {
                const res = await post(endpoint, data)
                await dispatch({
                    type: types.GENERATE_CHALLENGER_ID.success()
                })
                await dispatch({
                    type: opmlEntityTypes.OPML_OP_UPDATE,
                    payload: {
                        id: {
                            challenger: res.challengeId
                        },
                    }
                })
            } catch (error) {
                await dispatch({
                    type: types.GENERATE_CHALLENGER_ID.failure()
                })
            }

        }
    },
    challengerRespond: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.challengerRespond()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress
            })
            await dispatch({
                type: types.CHALLENGER_RESPOND.request()
            })
            try {
                const res = await post(endpoint, data)
                if (res.state === 'WAIT' || res.state === 'END') {
                    await dispatch({
                        type: types.CHALLENGER_RESPOND.success()
                    })
                    return Promise.resolve(res.state)
                } else {
                    await dispatch({
                        type: types.CHALLENGER_RESPOND.success()
                    })
                    await dispatch({
                        type: opmlEntityTypes.OPML_OP_UPDATE,
                        payload: {
                            checkpoints: {
                                ...opmlEntity.op.checkpoints,
                                challenger: res.config.checkpoints
                            },
                            roots: {
                                ...opmlEntity.op.roots,
                                challenger: res.root
                            }
                        }
                    })
                }
            } catch (error) {
                await dispatch({
                    type: types.CHALLENGER_RESPOND.failure()
                })
                return Promise.reject(error)
            }
        }
    },
    submitterRespond: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.submitterRespond()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress
            })
            await dispatch({
                type: types.SUBMITTER_RESPOND.request()
            })
            try {
                const res = await post(endpoint, data)
                if (res.state === 'WAIT' || res.state === 'END') {
                    await dispatch({
                        type: types.SUBMITTER_RESPOND.success()
                    })
                    return Promise.resolve(res.state)
                } else {
                    await dispatch({
                        type: opmlEntityTypes.OPML_OP_UPDATE,
                        payload: {
                            checkpoints: {
                                ...opmlEntity.op.checkpoints,
                                submitter: res.config.checkpoints
                            },
                            roots: {
                                ...opmlEntity.op.roots,
                                submitter: res.root
                            }
                        }
                    })
                }
                await dispatch({
                    type: types.SUBMITTER_RESPOND.success()
                })
            } catch (error) {
                await dispatch({
                    type: types.SUBMITTER_RESPOND.failure()
                })
                return Promise.reject(error)
            }
        }
    },
    challengerAssert: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.challengerAssert()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const data = JSON.stringify({
                contractAddress: opmlEntity.config.contractAddress
            })
            await dispatch({
                type: types.OPML_ASSERT.success()
            })
            try {
                const res = await post(endpoint, data)
                if (res.state === "NO DONE") {
                    await dispatch({
                        type: types.OPML_ASSERT.failure()
                    })
                    return Promise.resolve(res.state)
                }
                await dispatch({
                    type: types.OPML_ASSERT.success()
                })
                const result_events = JSON.stringify(res.events)
                let challengerWins = false
                if (result_events.includes("ChallengerWins")) {
                    challengerWins = true
                }
                // const msg = challengerWins ? "Challenger WINS! The submitted result is incorrect. Please regenerate!" : "Submitter WINS! The original ML inference (submitter's response) was deemed correct after the challenge process."
                const msg = 'the result is finalized on chain'
                await dispatch({
                    type: opmlEntityTypes.OPML_OP_UPDATE,
                    payload: {
                        assertResult: msg,
                    }
                })
                return Promise.resolve(msg)
            } catch (error) {
                await dispatch({
                    type: types.OPML_ASSERT.failure()
                })
            }
        }
    },
    postOpml: () => {
        return async (dispatch: any, getState: any) => {
            const endpoint = url.postOpml()
            const opmlEntity = (getState().entities.opml as OpmlEntityState)
            const data = JSON.stringify(opmlEntity.form)
            await dispatch(dispatchAction(
                POST_DATA,
                types.POST.all(),
                endpoint,
                null,
                data
            ))
        }
    },
    connectWallet: () => {
        return async (dispatch: any, getState: any) => {
            const opmlEntity = (getState().entities.opml as OpmlEntityState)

            const response = await changeNetwork(opmlEntity.network);
            await dispatch({
                type: walletTypes.WALLET_CONNECT,
                payload: {
                    address: response.userAddress,
                    chain: response.chainId
                }
            })
        }
    }
}

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const data = (state = initialState, action: Action) => {
    switch (action.type) {
        case types.GENERATE_OPML_IMAGE.request():
            return { ...state, isGeneratingImg: true };
        case types.GENERATE_OPML_IMAGE.success():
            return { ...state, isGeneratingImg: false };
        case types.GENERATE_OPML_IMAGE.failure():
            return { ...state, isGeneratingImg: false };
        case types.SUBMIT_GENERATED_OPML_IMAGE.request():
            return { ...state, isSubmittingGeneratedImg: true };
        case types.SUBMIT_GENERATED_OPML_IMAGE.success():
            return { ...state, isSubmittingGeneratedImg: false };
        case types.SUBMIT_GENERATED_OPML_IMAGE.failure():
            return { ...state, isSubmittingGeneratedImg: false };
        case types.REQUEST_OPML.request():
            return { ...state, isRequestingOpml: true };
        case types.REQUEST_OPML.success():
            return { ...state, isRequestingOpml: false };
        case types.REQUEST_OPML.failure():
            return { ...state, isRequestingOpml: false };
        case types.GENERATE_CHALLENGER_ID.request():
            return { ...state, isGeneratingChallengeId: true };
        case types.GENERATE_CHALLENGER_ID.success():
            return { ...state, isGeneratingChallengeId: false };
        case types.GENERATE_CHALLENGER_ID.failure():
            return { ...state, isGeneratingChallengeId: false };
        case types.CHALLENGER_RESPOND.request():
            return { ...state, isChallengerResponding: true };
        case types.CHALLENGER_RESPOND.success():
            return { ...state, isChallengerResponding: false };
        case types.CHALLENGER_RESPOND.failure():
            return { ...state, isChallengerResponding: false };
        case types.SUBMITTER_RESPOND.request():
            return { ...state, isSubmitterResponding: true };
        case types.SUBMITTER_RESPOND.success():
            return { ...state, isSubmitterResponding: false };
        case types.SUBMITTER_RESPOND.failure():
            return { ...state, isSubmitterResponding: false };
        case types.OPML_ASSERT.request():
            return { ...state, isAsserting: true };
        case types.OPML_ASSERT.success():
            return { ...state, isAsserting: false };
        case types.OPML_ASSERT.failure():
            return { ...state, isAsserting: false };
        case types.LLM_OUTPUT.request():
            return { ...state, isFetchingLLMOutput: true };
        case types.LLM_OUTPUT.success():
            return { ...state, isFetchingLLMOutput: false };
        case types.LLM_OUTPUT.failure():
            return { ...state, isFetchingLLMOutput: false };
        case types.POST.request():
            return { ...state, isLoadingPost: true };
        case types.POST.success():
            return { ...state, isLoadingPost: false };
        case types.POST.failure():
            return { ...state, isLoadingPost: false };
        case types.CONNECT_CONTRACT.request():
            return { ...state, isConnectingContract: true };
        case types.CONNECT_CONTRACT.success():
            return { ...state, isConnectingContract: false };
        case types.CONNECT_CONTRACT.failure():
            return { ...state, isConnectingContract: false };
        default:
            return state;
    }
}

const reducer = combineReducers({ data });

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getOpmlStatus = (state: any) => state.opml.data as OpmlState;

export default reducer;
